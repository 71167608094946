<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createAdminContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">
                  Update <b>{{ subscription.name }}</b> Subscription
                </h3>
              </div>
            </div>

            <b-row id="subscriptionForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-row v-if="loading">
                    <div class="col-lg-12 text-center">
                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                      <b>Loading...</b>
                    </div>
                  </b-row>
                  <b-card-body class="pt-0" v-else>
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row">
                            <div class="col-lg-12">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="name"
                                >
                                  Subscription Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  v-model.trim="subscription.name"
                                  data-vv-as="name"
                                  v-validate="'required'"
                                  data-vv-name="name"
                                  placeholder="Subscription Name"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('name'),
                                    'has-invalid': errors.has('name'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('name')"
                                >
                                  {{ errors.first("name") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label pb-2"
                                  for="content"
                                  >Content</label
                                >
                                <ckeditor
                                  v-if="ckeditorReady"
                                  id="contentBlackCard"
                                  name="content"
                                  :editor="editor"
                                  v-model="subscription.description"
                                  :config="editorConfig"
                                  v-validate="'required'"
                                  data-vv-name="content"
                                  data-vv-as="content"
                                  data-vv-scope="content"
                                  class="no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('content'),
                                    'has-invalid': errors.has('content'),
                                  }"
                                >
                                </ckeditor>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('content')"
                                >
                                  {{ errors.first("content") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="price"
                                  >Select Price
                                </label>
                                <b-form-select
                                  v-model="subscription.default"
                                  :options="prices"
                                  name="price"
                                  placeholder="Please select price"
                                  v-validate="'required'"
                                  data-vv-as="price"
                                  data-vv-name="price"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  :class="{
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('price'),
                                  }"
                                >
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('price')"
                                >
                                  {{ errors.first("price") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback w-25"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="numberofImages"
                                  >Number of images allowed</label
                                >
                                <b-form-spinbutton
                                  v-model="subscription.numberOfImages"
                                  min="1"
                                  max="6"
                                  v-validate="'required'"
                                  data-vv-as="Number of images"
                                  data-vv-name="numberofImages"
                                  class="form-control no-instruction"
                                  :class="{
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('numberofImages'),
                                  }"
                                >
                                </b-form-spinbutton>
                                <span
                                  class="
                                    has-icon-feedback
                                    numberOfImages-feedback
                                  "
                                ></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('numberofImages')"
                                >
                                  {{ errors.first("numberofImages") }}
                                </div>
                              </b-form-group>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link
                                ref="clear"
                                :to="`/settings/subscriptions`"
                                exact
                                class="
                                  btn btn-outline-danger
                                  pr-5
                                  pl-5
                                  w-sm-100
                                "
                                >Cancel</router-link
                              >
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button
                                type="button"
                                id="updateSubscription"
                                @click="updateSubscription()"
                                class="
                                  btn btn-primary
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                "
                              >
                                Update Subscription
                                <span
                                  class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
// import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'

import Link from "@ckeditor/ckeditor5-link/src/link";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import List from "@ckeditor/ckeditor5-list/src/list";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BlockToolbar from "@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import ParagraphButtonUI from "@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";

import { API_URL } from "@/api/constants/baseUrl";
import config from "config";
import { decoder } from "@/utils/sessions/jwt";

Vue.use(Cookies);

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          // EasyImage,
          EssentialsPlugin,
          Heading,
          BoldPlugin,
          ItalicPlugin,
          BlockQuote,
          Underline,
          LinkPlugin,
          ParagraphPlugin,
          Link,
          List,
          Alignment,
          Indent,
          IndentBlock,
          Font,
          HeadingButtonsUI,
          ParagraphButtonUI,
          Alignment,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          Autoformat,
        ],
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "blockQuote",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "outdent",
          "indent",
          "|",
          "undo",
          "redo",
          "|",
          "link",
          "|",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "alignment:left",
          "alignment:right",
          "alignment:center",
          "alignment:justify",
          "|",
          "imageUpload",
          "imageStyle:side",
          "imageStyle:full",
        ],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
          ],
          styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
          ],
        },
        indentBlock: {
          classes: [
            "custom-block-indent-a", // First step - smallest indentation.
            "custom-block-indent-b",
            "custom-block-indent-c", // Last step - biggest indentation.
          ],
        },
        cloudServices: {
          tokenUrl: API_URL + "/endpoint",
          uploadUrl: "https://79127.cke-cs.com/easyimage/upload/",
        },
      },
      subscriptionID: null,
      subscription: {
        name: null,
        description: "",
        default: null,
        numberOfImages: null,
        isActive: 1,
      },
      priceOptions: [],
      ckeditorReady: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.subscriptions.loading;
    },
    prices() {
      let data = [];
      this.priceOptions.map((price) => {
        data.push({
          value: price._id,
          text: `${price.currency} ${price.price} `,
        });
      });

      return [
        { value: null, text: "Please select price", disabled: true },
        ...data,
      ];
    },
  },
  methods: {
    getSubscription() {
      this.$store
        .dispatch("subscriptions/GET_SUBSCRIPTION_BY_ID", this.subscriptionID)
        .then((selectedSubscription) => {
          this.subscription = {
            name: selectedSubscription.name,
            description: selectedSubscription.description.toString(),
            default: selectedSubscription.default,
            isActive: selectedSubscription.isActive,
            numberOfImages: selectedSubscription.numberOfImages,
          };
          this.priceOptions = selectedSubscription.priceOptions;
          this.ckeditorReady = true;
        });
    },
    updateSubscription() {
      const promiseBtn = document.getElementById("updateSubscription");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("subscriptionForm");
      bodyWrapper.classList.add("non-clickable");
      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const subscriptionData = this.subscription;
          this.$store
            .dispatch("subscriptions/UPDATE_SUBSCRIPTION", {
              subscriptionID: this.subscriptionID,
              subscriptionData,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Subscription successfully updated"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.$router.push("/settings/subscriptions");
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Connection already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating connection"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }

              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords(
              "Please fill up the required fields."
            ),
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });
          bodyWrapper.classList.remove("non-clickable");
          promiseBtn.removeAttribute("disabled", "disabled");
        }
      });
    },
  },
  mounted() {
    this.action = this.$route.params.id ? "update" : "create";
    this.subscriptionID = this.$route.params.id;
    this.getSubscription();
  },
};
</script>
<style lang="scss">
.ck-content {
  background: #000000 !important;
  min-height: 400px;
}
body {
  color: white;
}
.numberOfImages-feedback {
  right: 40px !important;
  top: 42px;
}
</style>
