<template>
  <div class="adminList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Notifications</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <router-link
              :to="`/notifications/create`"
              class="btn btn-primary opacity-8 float-right"
            >
              <i class="fa fa-plus-circle"></i>
              Send Notification
            </router-link>
          </div>
        </div>
        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-container>
                  <!-- <b-row>
                    <b-col class="d-flex">
                      <div class="mr-3 text-nowrap">User Status</div>
                      <b-form-select v-model="selectedStatus" :options="statuses" name="Filter" :disabled="loading" class="float-right"/>
                    </b-col>
                    <b-col>
                    </b-col>
                    <b-col>
                        <base-input v-model="searchRequest"  :disabled="loading" prepend-icon="fas fa-search" placeholder="Search"></base-input>
                    </b-col>
                  </b-row> -->
                </b-container>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="notificationTable"
                  id="notificationTable"
                  responsive
                  hover
                  :items="notificationsList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                  :filter-included-fields="['fullName', 'email']"
                  @context-changed="loadInitialPendingVisibleItems"
                >
                </b-table>
                <b-row v-if="notificationsList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center"
                />
                <div
                  class="float-right"
                  v-if="totalRows > perPage && notificationsList.length !== 0"
                >
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Cookies from "js-cookie";
import moment from "moment";
import showConfirmation from "@/utils/mixins/deleteNotify";

Vue.use(Cookies);

export default {
  name: "AdminList",
  mixins: [showConfirmation],
  data: function () {
    return {
      listCollection: [],
      errorFields: [],
      transactionID: "",
      notificationsListFilter: [],
      searchRequest: "",
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      allTimeStatus: false,
      selectedFilter: "all",
      selectedStatus: "all",
      statuses: [
        { value: "all", text: "All" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
        { value: "deleted", text: "Deleted" },
      ],
      sortBy: "dateCreated",
      sortDesc: true,
      ListFields: [
        {
          key: "title",
          label: "Notification Title",
          sortable: true,
        },
        {
          key: "message",
          label: "Message",
          sortable: true,
        },
        {
          key: "city",
          label: "City",
          sortable: true,
        },
        {
          key: "section",
          label: "Section",
          sortable: true,
        },
        {
          key: "category",
          label: "Category",
          sortable: true,
        },
        {
          key: "sub_category",
          label: "Sub Category",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Created",
          sortable: true,
          tdClass: "createdAtCol",
        },
      ],
      updateAccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.messages.loading;
    },
    notificationsList: {
      get: function () {
        return this.notificationsListFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadNotifications(currentPage) {
      this.$store.dispatch("messages/GET_NOTIFICATIONS").then((resp) => {
        var filter = "all";
        this.notificationsListFilter =
          this.$store.state.messages.notifications[filter];
        this.totalRows = this.notificationsListFilter.length;
      });
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    deleteProcess(id, item) {
      const adminUserID = String(id).trim();
      const fullName = String(item.fullName).trim();
      const email = String(item.email).trim();
      // const role = item.role === '1' ? 'Super Admin' : 'Admin'
      const otherInfo = `<br/>
                          <table class="table table-borderless table-popup">
                            <tr><td><strong>Full Name</strong></td><td>${fullName}</td></tr>
                            <tr><td><strong>Email</strong></td><td>${email}</td></tr>
                          </table>`;
      this.showConfirmation(
        this.deleteAdminUser,
        adminUserID,
        "this admin user? Please confirm the details below:",
        otherInfo,
        true
      );
    },
    deleteAdminUser(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch("admin/DELETE_ADMIN_USER", userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Admin user successfully deleted"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadNotifications(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error deleting admin user"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    resetPassword(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch("user/RESET_PASSWORD_REQUEST", userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Email sent to user to reset the password"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error resetting user password"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    activateAdminUser(currentStatus, id) {
      let isActive = 0;
      let statusValue = "";
      const userID = String(id).trim();

      if (currentStatus === 1) {
        isActive = 0;
        statusValue = "Deactivated";
      } else {
        isActive = 1;
        statusValue = "Activated";
      }

      const status = { isActive };

      this.$store
        .dispatch("user/UPDATE_USER_STATUS", { userID, status })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Admin user successfully " + statusValue
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadNotifications(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error creating user"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.notificationsListFilter =
        this.$store.state.admin.notifications[context];
      this.totalRows = this.notificationsListFilter.length;
    },
  },
  mounted() {
    this.loadNotifications(this.currentPage);
  },
};
</script>
<style lang="scss">
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}

.createdAtCol {
  width: 180px;
}
.text-break-all {
  word-break: break-all;
}
.target-fade-cancel {
  -webkit-animation: target-fade-cancel 2s 1;
  -moz-animation: target-fade-cancel 2s 1;
}

@-webkit-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

@-moz-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

.target-fade-complete {
  -webkit-animation: target-fade-complete 2s 1;
  -moz-animation: target-fade-complete 2s 1;
}

@-webkit-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}

@-moz-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}
</style>
