<template>
  <div class="adminList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Advertisement List</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <!-- <router-link :to="`/user/business-clients/create`" class="btn btn-aqua opacity-8 float-right">
              <i class="fa fa-plus-circle"></i>
              Add New Business Client
            </router-link> -->
          </div>
        </div>
        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-container>
                  <b-row>
                    <b-col class="d-flex">
                      <div class="mr-3 text-nowrap">Business Ads Status</div>
                      <b-form-select
                        v-model="selectedStatus"
                        :options="statuses"
                        name="Filter"
                        :disabled="loading"
                        class="float-right"
                      />
                    </b-col>
                    <b-col> </b-col>
                    <b-col>
                      <base-input
                        v-model="searchRequest"
                        :disabled="loading"
                        prepend-icon="fas fa-search"
                        placeholder="Search"
                      ></base-input>
                    </b-col>
                  </b-row>
                </b-container>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="adsTable"
                  id="adsTable"
                  responsive
                  hover
                  :items="adsList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                  :filter-included-fields="['adsOwner', 'city']"
                  @context-changed="loadInitialPendingVisibleItems"
                >
                  <template v-slot:cell(adsCover)="data">
                    <div class="data adsListCover">
                      <img width="100px" :src="data.item.adsCover" alt="" />
                    </div>
                  </template>
                  <template v-slot:cell(businessName)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">{{
                        data.item.businessName
                      }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(businessEmail)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">{{
                        data.item.businessEmail
                      }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(city)="data">
                    <div class="data">
                      <span
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                        >{{ data.item.city }} <br /><span
                          v-if="data.item.city_status == false"
                          class="badge badge-pill text-white bg-warning"
                          >Hidden</span
                        ></span
                      >
                    </div>
                  </template>
                  <template v-slot:cell(createdAt)="data">
                    <div class="data">
                      <span
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                        >{{ data.item.createdYmd }}
                        {{ data.item.createdHms }}</span
                      >
                    </div>
                  </template>
                  <template v-slot:cell(status)="data">
                    <div class="data">
                      <div
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                      >
                        <span
                          :class="{
                            'bg-success': data.item.status === 'approved',
                            'bg-warning': data.item.status === 'pending',
                            'bg-danger':
                              data.item.status === 'rejected' ||
                              data.item.status === 'expired',
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.status }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(featured)="data">
                    <div class="data">
                      <div
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                      >
                        <span
                          :class="{
                            'bg-success': data.item.featured == true,
                            'bg-light': data.item.featured == false,
                            'bg-danger': data.item.featured === 'rejected',
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.featured == true ? "Yes" : "No" }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(showAd)="data">
                    <div class="data">
                      <div
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                      >
                        <span
                          :class="{
                            'bg-success': data.item.showAd == true,
                            'bg-light': data.item.showAd == false,
                            'bg-danger': data.item.showAd === 'rejected',
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.showAd == true ? "Yes" : "No" }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data" class="data">
                    <div>
                      <router-link
                        :to="`/business/ads/list/edit/${data.item.actions}`"
                        v-b-tooltip.hover
                        title="View Ads"
                        class="btn btn-outline-primary btn-sm"
                      >
                        <span class="btn-inner--icon"
                          ><i class="far fa-edit"></i>
                        </span>
                      </router-link>
                      <a
                        v-if="data.item.status == 'approved'"
                        @click="
                          setAsFeatured(data.item.featured, data.item.actions)
                        "
                        v-b-tooltip.hover
                        :title="
                          data.item.featured == true
                            ? 'Remove as featured'
                            : 'Set as featured'
                        "
                        class="btn btn-outline-vobo btn-sm text-vobo"
                      >
                        <span class="btn-inner--icon"
                          ><i
                            :class="
                              data.item.featured == true
                                ? 'fas fa-toggle-on'
                                : 'fas fa-toggle-off'
                            "
                          ></i>
                        </span>
                      </a>
                      <!-- <a @click="deleteProcess(data.item.actions, data.item)" v-b-tooltip.hover title="Delete Business Client User Account" class="btn btn-outline-danger btn-sm text-danger">
                          <span class="btn-inner--icon"><i class="far fa-trash-alt"></i> </span>
                        </a> -->

                      <!-- <a @click="activateAdminUser(data.item.isActive, data.item.actions)" v-b-tooltip.hover :title="data.item.isActive === 1 ? 'Deactivate Admin User': 'Activate Admin User'" class="btn btn-outline-vobo btn-sm text-vobo">
                          <span class="btn-inner--icon"><i :class="data.item.isActive === 1 ? 'fas fa-toggle-on': 'fas fa-toggle-off'"></i> </span>
                        </a> -->
                    </div>
                  </template>
                </b-table>
                <b-row v-if="adsList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center"
                />
                <div
                  class="float-right"
                  v-if="totalRows > perPage && adsList.length !== 0"
                >
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Cookies from "js-cookie";
import moment from "moment";
import showConfirmation from "@/utils/mixins/deleteNotify";

Vue.use(Cookies);

export default {
  name: "adsList",
  mixins: [showConfirmation],
  data: function () {
    return {
      listCollection: [],
      errorFields: [],
      transactionID: "",
      businessAdsListFilter: [],
      searchRequest: "",
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      allTimeStatus: false,
      selectedFilter: this.$route.query.status || "all",
      selectedStatus: this.$route.query.status || "all",
      statuses: [
        { value: "all", text: "All" },
        { value: "pending", text: "Pending" },
        { value: "approved", text: "Approved" },
      ],
      sortBy: "dateCreated",
      sortDesc: true,
      ListFields: [
        {
          key: "adsCover",
          label: "Advertisement Cover",
          sortable: true,
        },
        {
          key: "adsOwner",
          label: "Owned by business",
          sortable: true,
        },
        {
          key: "city",
          label: "Location",
          sortable: true,
        },
        {
          key: "businessCategory",
          label: "Business Category",
          sortable: true,
        },
        {
          key: "businessSubCategory",
          label: "Business Sub Category",
          sortable: true,
        },
        {
          key: "status",
          label: "Ads Status",
          sortable: true,
        },
        {
          key: "featured",
          label: "Featured",
          sortable: true,
        },
        {
          key: "showAd",
          label: "Show Ad",
          sortable: true,
        },
        {
          key: "actions",
          label: "actions",
          tdClass: "actionsCol",
        },
      ],
      updateAccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.ads.loading;
    },
    adsList: {
      get: function () {
        return this.businessAdsListFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadBusiness(currentPage) {
      this.$store.dispatch("ads/GET_ADS_LIST").then((resp) => {
        var filter = this.$route.query.status || "all";
        this.businessAdsListFilter = this.$store.state.ads.adsList[filter];
        this.totalRows = this.businessAdsListFilter.length;
      });
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    setAsFeatured(currentStatus, id) {
      let status = false;
      let statusValue = "";
      const adsID = String(id).trim();

      if (currentStatus === true) {
        status = false;
        statusValue = "Removed as featured";
      } else {
        status = true;
        statusValue = "Set as featured";
      }

      let adsData = {
        featured: status,
      };

      this.$store
        .dispatch("ads/SET_FEATURED", { adsID, adsData })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Ads successfully " + statusValue
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadBusiness(this.currentPage);
          } else {
            this.$notify({
              message:
                this.$options.filters.capitalizeWords("Error creating ads"),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    deleteProcess(id, item) {
      console.log(item);
      const businessID = String(id).trim();
      const businessName = String(item.businessName).trim();
      const businessOwner = String(item.businessOwner).trim();
      const otherInfo = `<br/>
                          <table class="table table-borderless table-popup">
                            <tr><td><strong>Business Name</strong></td><td>${businessName}</td></tr>
                            <tr><td><strong>Business Owner</strong></td><td>${businessOwner}</td></tr>
                          </table>`;

      this.showConfirmation(
        this.deleteBusiness,
        businessID,
        "this ads? Please confirm the details below:",
        otherInfo,
        true
      );
    },
    deleteBusiness(id) {
      const businessID = String(id).trim();
      this.$store
        .dispatch("business/DELETE_BUSINESS", businessID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Ads successfully deleted"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadBusiness(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error deleting business"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      console.log(context);
      this.businessAdsListFilter = this.$store.state.ads.adsList[context];
      this.totalRows = this.businessAdsListFilter.length;
    },
  },
  mounted() {
    this.loadBusiness(this.currentPage);
  },
};
</script>
<style lang="scss">
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}

a.btn-outline-danger:hover {
  color: #fff !important;
}
a.btn-outline-vobo:hover {
  color: #fff !important;
}
a.btn-outline-default:hover {
  color: #fff !important;
}
a.btn-outline-primary:hover {
  color: #fff !important;
}

.text-break-all {
  word-break: break-all;
}

.target-fade-cancel {
  -webkit-animation: target-fade-cancel 2s 1;
  -moz-animation: target-fade-cancel 2s 1;
}

.data.adsListCover {
  img {
    display: block;
    margin: auto;
  }
}

.actionsCol {
  display: flex;
  width: 100px;
}

@-webkit-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

@-moz-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

.target-fade-complete {
  -webkit-animation: target-fade-complete 2s 1;
  -moz-animation: target-fade-complete 2s 1;
}

@-webkit-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}

@-moz-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}
</style>
