<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createAdminContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">View Advertisement</h3>
              </div>
            </div>
            <b-row v-if="loading">
              <div class="col-lg-12 text-center">
                <pulse-loader color="var(--pulse-loader)"></pulse-loader>
              </div>
            </b-row>
            <b-row v-else id="processAdvertisementForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-5">
                              <div v-if="adsStatus != undefined">
                                <h3 class="mb-2 mx-auto">
                                  <label for="" class="form-control-label mr-2"
                                    >Status : </label
                                  ><span
                                    :class="{
                                      'bg-success':
                                        this.adsStatus == 'approved',
                                      'bg-warning': this.adsStatus == 'pending',
                                      'bg-danger':
                                        this.adsStatus == 'rejected' ||
                                        this.adsStatus == 'expired',
                                    }"
                                    class="badge badge-pill text-white"
                                    >{{ adsStatus }}</span
                                  >
                                </h3>
                              </div>
                              <div
                                v-if="
                                  (adsStatus == 'rejected' && hasAdminNote) ||
                                  (adsStatus == 'pending' && hasAdminNote)
                                "
                                class="mb-5"
                              >
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="
                                      w-100
                                      form-control-label
                                      reason-label
                                    "
                                    for="reason"
                                    >Reason for rejecting
                                  </label>
                                  <textarea
                                    id="reason"
                                    name="reason"
                                    v-model.trim="business.adminNote"
                                    v-validate="'required'"
                                    data-vv-name="reason"
                                    data-vv-as="reason"
                                    placeholder="Enter Reason for rejecting"
                                    maxlength="200"
                                    rows="4"
                                    class="form-control reason-field"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('reason'),
                                      'has-invalid': errors.has('reason'),
                                    }"
                                  >
                                  </textarea>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('reason')"
                                  >
                                    {{ errors.first("reason") }}
                                  </div>
                                </b-form-group>
                              </div>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="short"
                                  >Short Description</label
                                >
                                <input
                                  type="text"
                                  name="short"
                                  v-model.trim="business.description.short"
                                  data-vv-as="short"
                                  v-validate="'required'"
                                  data-vv-name="short"
                                  placeholder="Business Short Description"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('short'),
                                    'has-invalid': errors.has('short'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('short')"
                                >
                                  {{ errors.first("short") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="longDescription"
                                  >Long Description
                                </label>
                                <textarea
                                  id="longDescription"
                                  name="long"
                                  v-model.trim="business.description.long"
                                  v-validate="'required'"
                                  data-vv-name="long"
                                  data-vv-as="long description"
                                  class="form-control no-instruction long"
                                  placeholder="Enter Long Description"
                                  rows="4"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('long'),
                                    'has-invalid': errors.has('long'),
                                  }"
                                >
                                </textarea>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('long')"
                                >
                                  {{ errors.first("long") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="communityCategory"
                                  >Select Community Category
                                </label>
                                <b-form-select
                                  v-model="communityCategory"
                                  :options="communityCategories"
                                  name="communityCategory"
                                  placeholder="Please select community category"
                                  v-validate="'required'"
                                  data-vv-as="communityCategory"
                                  data-vv-name="communityCategory"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  :class="{
                                    'is-invalid':
                                      errors.has('communityCategory'),
                                    'has-invalid':
                                      errors.has('communityCategory'),
                                  }"
                                  @change="selectSubCategory()"
                                >
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('communityCategory')"
                                >
                                  {{ errors.first("communityCategory") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                v-if="communityCategory != null"
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <div v-if="subCategories.length > 1">
                                  <label
                                    class="w-100 form-control-label"
                                    for="subCategory"
                                    >Select Sub Category
                                  </label>
                                  <b-form-select
                                    v-model="communitySubCategory"
                                    :options="subCategories"
                                    name="subCategory"
                                    placeholder="Please select subCategory"
                                    v-validate="'required'"
                                    data-vv-as="subCategory"
                                    data-vv-name="subCategory"
                                    class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    "
                                    :class="{
                                      'is-invalid': errors.has('subCategory'),
                                      'has-invalid': errors.has('subCategory'),
                                    }"
                                  >
                                  </b-form-select>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('subCategory')"
                                  >
                                    {{ errors.first("subCategory") }}
                                  </div>
                                </div>
                                <div v-else>
                                  <label
                                    class="w-100 form-control-label error-msg"
                                    for="error"
                                    >No Sub Category Found</label
                                  >
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="city"
                                  >Select City
                                </label>
                                <b-form-select
                                  v-model="business.city"
                                  :options="cities"
                                  name="city"
                                  placeholder="Please select city"
                                  v-validate="'required'"
                                  data-vv-as="city"
                                  data-vv-name="city"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  :class="{
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('city'),
                                  }"
                                >
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('city')"
                                >
                                  {{ errors.first("city") }}
                                </div>
                              </b-form-group>
                            </div>
                            <div class="col-lg-7">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <!-- <label class="w-100 form-control-label" for="short">Current Cover</label> -->
                                <h3 class="img-title" v-if="editingImage">Edit Image</h3>
                                <!-- <img width="100%" v-if="business.cover" :src="business.cover.url" alt=""> -->
                                <cropper
                                  ref="cover"
                                  class="cropper"
                                  v-if="business.cover && allowedNumberOfImages == 1"
                                  :src="business.cover.url"
                                  :stencil-props="{
                                    aspectRatio: limitations.aspectRatio,
                                  }"
                                  @change="coverOnChange"
                                />
                                <cropper
                                  ref="editImage"
                                  class="cropper"
                                  v-if="editingImage || allowedNumberOfImages == 1"
                                  :src="currentImages[editImageIndex].url"
                                  :stencil-props="{
                                    aspectRatio: limitations.aspectRatio, 
                                  }"
                                  @change="editImageOnChange"
                                />
                              </b-form-group>

                              <div class="preview-image">
                                <div class="cover">
                                  <div class="row">
                                    <h3>Choose Cover</h3>
                                  </div>
                                </div>
                                <ul class="instructions">
                                  <li>Load new images by clicking the <strong>Add Image(s)</strong> button</li>
                                  <li>Choose a cover image by clicking <b-icon icon="check-square-fill" class="action-icon select-icon"></b-icon></li>
                                  <li>Your chosen cover image will show <b-icon icon="check-square-fill" class="action-icon selected-icon"></b-icon></li>
                                  <li>Remove an image by clicking <b-icon icon="x-octagon-fill" class="action-icon delete-icon"></b-icon></li>
                                  <li>Update the crop of an image by clicking on the image</li>
                                  <li class="note-important">Avoid uploading sexually explicit and sexual images.</li>
                                  <li>Please use 4:3 aspect ratio for best results</li>
                                </ul>
                                <div class="images">
                                  <div class="row preview-container">
                                    <!-- Current Image Section -->
                                    <div
                                      v-for="(img, index) in (allowedNumberOfImages > 1 ? currentImages : [])"
                                      :key="img._id"
                                      class="col-lg-3"
                                    >
                                      <div class="img-container editable-image">
                                        <div>
                                          <div class="action">
                                            <div
                                              v-if="coverImageId !== img._id"
                                              class="select-icon"
                                              @click.prevent="
                                                selectMainImage(index, img)
                                              "
                                            >
                                              <b-icon
                                                icon="check-square-fill"
                                                class="action-icon"
                                              ></b-icon>
                                            </div>
                                            <div v-else class="selected-icon">
                                              <b-icon
                                                icon="check-square-fill"
                                                class="action-icon"
                                              ></b-icon>
                                            </div>
                                            <!-- <div class="update-icon" @click.prevent="updatePreview(img)" >
                                                  <b-icon icon="card-image" class="action-icon"></b-icon>
                                                </div> -->
                                            <div
                                              v-if="coverImageId !== img._id"
                                              class="delete-icon"
                                              @click.prevent="deleteImage(index, img)"
                                            >
                                              <b-icon
                                                icon="x-octagon-fill"
                                                class="action-icon"
                                              ></b-icon>
                                            </div>
                                          </div>
                                          <div
                                            class="current-img"
                                            :class="{
                                              selected: index == editImageIndex,
                                            }"
                                            @click.prevent="
                                              selectEditImage(index, img)
                                            "
                                          >
                                            <img :src="img.url" alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Add New Image Section -->
                                    <div
                                      v-if="
                                        currentImages.length <
                                          allowedNumberOfImages ||
                                        allowedNumberOfImages == 1
                                      "
                                      :class="[
                                        {
                                          'col-lg-3': allowedNumberOfImages > 1,
                                        },
                                        {
                                          'col-lg-4':
                                            allowedNumberOfImages == 1,
                                        },
                                      ]"
                                    >
                                      <div class="img-container">
                                        <div class="action">
                                          <b-form-file
                                            accept="image/*"
                                            v-model="urlImage"
                                            data-vv-as="uploadImages"
                                            data-vv-name="uploadImages"
                                            ref="fileUpload"
                                            style="display: none"
                                            @change="onFileChange"
                                            @ondrop="onFileChange"
                                            @ondragover="onFileChange"
                                            :multiple="
                                              allowedNumberOfImages > 1
                                            "
                                            placeholder="Choose a file or drop it here..."
                                            drop-placeholder="Drop file here..."
                                          ></b-form-file>
                                          <div
                                            v-if="(currentImages.length + newImages.length) < allowedNumberOfImages || allowedNumberOfImages == 1"
                                            class="add-icon"
                                            @click="triggerUpload()"
                                            v-b-tooltip.hover.top="
                                              'Please use 4:3 aspect ratio for best results.'
                                            "
                                          >
                                            <p>
                                              {{
                                                allowedNumberOfImages > 1
                                                  ? "Add Image(s)"
                                                  : "Update Image"
                                              }}
                                            </p>
                                            <b-icon
                                              icon="plus-circle-fill"
                                              class="action-icon"
                                            ></b-icon>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-12 mt-2">
                              <div
                                v-if="newImages.length > 0"
                                class="preview-image"
                              >
                                <div class="cover-two">
                                  <div class="row">
                                    <h3>New Image(s)</h3>
                                  </div>
                                </div>
                                <div class="images mt-2">
                                  <div class="row preview-container">
                                    <!-- Current Image Section -->
                                    <div
                                      v-for="img in newImages"
                                      :key="img.index"
                                      class="col-lg-4"
                                    >
                                      <div class="img-container">
                                        <div>
                                          <div class="current-img">
                                            <cropper
                                              ref="cropper"
                                              class="cropper"
                                              :src="img.image"
                                              :stencil-props="{
                                                aspectRatio:
                                                  limitations.aspectRatio,
                                              }"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4 px-4">
                            <div class="col-lg-6 col-md-4 col-12">
                              <router-link
                                ref="clear"
                                :to="`/business/ads/list`"
                                exact
                                class="
                                  btn btn-outline-danger
                                  pr-5
                                  pl-5
                                  w-sm-100
                                "
                                >Cancel</router-link
                              >
                              <button
                                v-if="business.status != 'expired'"
                                type="button"
                                id="processBusinessUpdates"
                                @click="processBusinessUpdates(businessID)"
                                class="
                                  btn btn-primary
                                  w-sm-100
                                  mb-3 mb-md-0
                                  mx-2
                                "
                              >
                                <!-- {{ action ==='create' ? 'Create Business' : 'Update Business' }} -->
                                <b-icon
                                  icon="arrow-clockwise"
                                  animation="spin"
                                  class="btn-loader hidden"
                                  font-scale="1"
                                ></b-icon>
                                Update Advertisement
                                <span class="promise-btn__spinner-wrapper"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                                <span
                                  class="
                                    promise-btn__spinner-wrapper
                                    update
                                    hidden
                                  "
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                            <!-- {{ () }} -->
                            <div class="col-lg-6 col-md-4 col-12">
                              <button
                                v-if="
                                  (adsStatus == 'rejected' && hasAdminNote) ||
                                  (adsStatus == 'pending' &&
                                    hasAdminNote &&
                                    business.status != 'expired')
                                "
                                type="button"
                                id="updateRejectBtn"
                                @click="updateRejectReason(business.adminNote)"
                                class="
                                  btn btn-danger
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                  mx-2
                                "
                              >
                                Update Reason
                                <span
                                  class="
                                    promise-btn__spinner-wrapper
                                    reject
                                    hidden
                                  "
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>

                              <button
                                v-if="
                                  (adsStatus == 'rejected' && !hasAdminNote) ||
                                  (adsStatus == 'pending' && !hasAdminNote) ||
                                  (adsStatus == 'approved' &&
                                    business.status != 'expired')
                                "
                                type="button"
                                id="rejectBtn"
                                @click="rejectAds()"
                                class="
                                  btn btn-danger
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                  mx-2
                                "
                              >
                                <!-- {{ action ==='create' ? 'Create Business' : 'Approve Advertisement' }} -->
                                {{ rejectClicked ? "Submit" : "Reject" }}
                                <span
                                  class="
                                    promise-btn__spinner-wrapper
                                    reject
                                    hidden
                                  "
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>

                              <div v-if="showReasonField">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <input
                                    type="text"
                                    name="adminNote"
                                    v-model.trim="adminNote"
                                    v-validate="'required'"
                                    data-vv-as="Reason for rejecting"
                                    data-vv-name="adminNote"
                                    placeholder="Reason for rejecting"
                                    autocomplete="NoAutocomplete"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('adminNote'),
                                      'has-invalid': errors.has('adminNote'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('adminNote')"
                                  >
                                    {{ errors.first("adminNote") }}
                                  </div>
                                </b-form-group>
                              </div>
                              <button
                                v-if="
                                  showApproveButton &&
                                  adsStatus != 'approved' &&
                                  business.status != 'expired'
                                "
                                type="button"
                                id="approveBtn"
                                @click="approveAds(adsID)"
                                class="
                                  btn btn-primary
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                  mx-2
                                "
                              >
                                <!-- {{ action ==='create' ? 'Create Business' : 'Approve Advertisement' }} -->
                                Approve
                                <span
                                  class="
                                    promise-btn__spinner-wrapper
                                    approve
                                    hidden
                                  "
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import showConfirmation from "@/utils/mixins/rejectNotify";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

Vue.use(Cookies);

export default {
  name: "BusinessAdsForm",
  mixins: [showConfirmation],
  components: {
    Cropper,
  },
  data() {
    return {
      DEFAULT_COVER_ID: 'cover',
      business: {
        name: "",
        category: null,
        city: null,
        location: {
          address: {
            line1: "",
            line2: "",
          },
        },
        email: "",
        phone: "",
        description: {
          long: "",
          short: "",
        },
        images: [],
        cover: undefined,
      },
      businessID: "",
      showAds: "",
      adsStatus: "",
      adminNote: "",
      hasAdminNote: false,
      newImages: [],
      currentImages: [],
      deletedImages: [],
      coverImageId: "",
      showReasonField: false,
      showApproveButton: true,
      rejectClicked: false,
      action: "update",
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      urlImage: [],
      categories: [
        { value: null, text: "Please select role", disabled: true },
        { value: 1, text: "News" },
        { value: 2, text: "Dining" },
        { value: 3, text: "Arts & Entertainment" },
        { value: 4, text: "Community" },
        { value: 5, text: "Business" },
        { value: 6, text: "Wellness" },
      ],
      formData: new FormData(),
      communityCategory: null,
      communitySubCategory: null,
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      allowedNumberOfImages: null,
      newPreviewImage: null,
      updatedCoverPreview: null,
      updatedCoverCoordinates: null,
      coordinatesSet: false,
      previewFileExtension: "",
      editImageIndex: -1,
      editingImage: false,
    };
  },
  computed: {
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [
        { value: null, text: "Please select city", disabled: true },
        ...data,
      ];
    },
    cityList: {
      get: function () {
        return this.$store.state.cities.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    communityCategories() {
      let data = [];
      this.communityCategoryList.map((communityCategory) => {
        data.push({
          value: communityCategory._id,
          text: communityCategory.title,
        });
      });

      return [
        {
          value: null,
          text: "Please select community category",
          disabled: true,
        },
        ...data,
      ];
    },
    communityCategoryList: {
      get: function () {
        return this.$store.state.connections.communityCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    subCategories() {
      let data = [];
      this.subCategoryList.map((subCategory) => {
        data.push({ value: subCategory._id, text: subCategory.title });
      });

      return [
        { value: null, text: "Please select sub category", disabled: true },
        ...data,
      ];
    },
    subCategoryList: {
      get: function () {
        return this.$store.state.connections.subCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.ads.loading;
    },
  },
  methods: {
    editImageOnChange({ coordinates, canvas }) {
      const { editImage } = this.$refs;
      // Check if cropper is loaded or changed
      if (this.coordinatesSet == false) {
        // If loaded, set coordinates from DB
        editImage.setCoordinates(this.currentImages[this.editImageIndex].previewCoordinates)
        // Set to true if the coordinates is set
        this.coordinatesSet = true;
      } else {
        this.currentImages[this.editImageIndex].previewCoordinates = coordinates
        this.currentImages[this.editImageIndex].preview = null
        this.currentImages[this.editImageIndex].previewFile = this.dataURLtoFile(
          canvas.toDataURL(),
          `${this.convertToSlug(this.business.name)}-${Date.now()}.${
            this.previewFileExtension
          }`
        )
      }
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    makeid(length) {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    dataURLtoFile(dataurl, filename) {
      let name = filename ? filename : this.makeid(10);
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name, { type: mime });
    },
    selectSubCategory() {
      this.communitySubCategory = null;
      this.getSubCategories();
    },
    triggerUpload() {
      this.$refs.fileUpload.$el.childNodes[0].click();
    },
    selectMainImage(index, img) {
      console.log("Select Main Image")
      this.coverImageId = img._id
    },
    selectEditImage(index, img) {
      this.editImageIndex = index
      this.coordinatesSet = false;
      this.editingImage = true;
      this.previewFileExtension = img.url.split(/[#?]/)[0].split(".").pop().trim();
    },
    deleteImage(index, image) {
      const currentEditId = this.editingImage ? this.currentImages[this.editImageIndex]._id : null
      this.currentImages = this.currentImages.filter(i => i._id != image._id);
      this.deletedImages.push(image._id)
      if (this.coverImageId == image._id) {
        this.coverImageId = this.currentImages.length > 0 ? this.currentImages[0]._id : ""
      }
      if (this.editingImage) {
        if (this.editImageIndex == index) {
          this.editingImage = false;
          this.coordinatesSet = false;
          this.editImageIndex = null;
          this.previewFileExtension = null;
        } else {
          this.editImageIndex = this.currentImages.findIndex(i => i._id == currentEditId)
        }
      }
    },
    getAdsInformation(adsID) {
      this.$store.dispatch("ads/GET_ADS_BY_ID", adsID).then((selectedAds) => {
        let category;
        let sub_category;

        // Check if category is removed
        if (!selectedAds.category) {
          category = null;
          sub_category = null;
        } else {
          // Check if no sub category
          category = selectedAds.category.parent
            ? selectedAds.category.parent
            : selectedAds.category._id;
          sub_category = selectedAds.category.parent
            ? selectedAds.category._id
            : null;
        }

        this.business = {
          ...selectedAds.business,
          city: selectedAds.city,
          images: selectedAds.business.images || [],
          description: selectedAds.business.description || {short: '', long: ''}
        };
        this.allowedNumberOfImages = selectedAds.plan.numberOfImages;
        this.communityCategory = category;
        this.communitySubCategory = sub_category;
        this.businessID = selectedAds.business._id;
        this.showAds = selectedAds.showAd;
        this.adminNote = this.business.adminNote;
        this.hasAdminNote =
          this.business.adminNote === "" || this.business.adminNote == undefined
            ? false
            : true;
        this.adsStatus = selectedAds.business.status;
        this.currentImages = JSON.parse(
          JSON.stringify([this.business.cover, ...this.business.images])
        );

        this.coverImageId = this.business.cover._id || this.DEFAULT_COVER_ID;
        this.business.cover._id = this.coverImageId;
        this.currentImages = JSON.parse(
          JSON.stringify([{...this.business.cover, _id: this.coverImageId}, ...this.business.images])
        )

        this.getSubCategories();
      });
    },
    processBusinessUpdates(userID) {
      if (this.action === "create") {
        this.addNewBusinessClient();
      } else {
        this.updateBusinessClient(userID);
      }
    },
    updateBusinessClient() {
      const bodyWrapper = document.getElementById("processAdvertisementForm");
      const updateBtn = document.getElementById("processBusinessUpdates");
      const approveBtn = document.getElementById("approveBtn");
      const rejectBtn = document.getElementById("rejectBtn");
      const updateRejectBtn = document.getElementById("updateRejectBtn");
      const btnLoader = updateBtn.querySelector(".btn-loader");

      btnLoader.classList.remove("hidden");
      bodyWrapper.classList.add("non-clickable");
      updateBtn.setAttribute("disabled", "disabled");
      approveBtn ? approveBtn.setAttribute("disabled", "disabled") : false;
      rejectBtn ? rejectBtn.setAttribute("disabled", "disabled") : false;
      updateRejectBtn
        ? updateRejectBtn.setAttribute("disabled", "disabled")
        : false;

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const businessData = this.business;
          const businessID = this.businessID;

          businessData.category = this.communitySubCategory

          // The request is from admin
          const query = {
            updatedBy: "Client",
          };

          if (this.deletedImages.length > 0) {
            businessData.deletedImages = this.deletedImages;
            if (this.deletedImages.findIndex(i => i == this.business.cover._id) >= 0) {
              businessData.deleteCover = true
            }
          }

          const coverIndex = this.currentImages.findIndex(i => i._id == this.coverImageId);
          businessData.cover = {
            previewCoordinates: this.currentImages[coverIndex].previewCoordinates,
            url: this.currentImages[coverIndex].url,
            preview: this.currentImages[coverIndex].preview,
            type: this.currentImages[coverIndex].type || 'image',
          }
          
          businessData.images = []
          for (var i = 0; i < this.currentImages.length; i++) {
            let img = {
              _id: this.currentImages[i]._id != this.DEFAULT_COVER_ID ? this.currentImages[i]._id : undefined,
              previewCoordinates: this.currentImages[i].previewCoordinates,
              url: this.currentImages[i].url,
              preview: this.currentImages[i].preview,
            }
            if (this.currentImages[i].previewFile) {
              if (this.currentImages[i]._id == this.coverImageId) {
                this.formData.append('cover_preview', this.currentImages[i].previewFile);
              } else {
                this.formData.append(`image_${businessData.images.length}_preview`, this.currentImages[i].previewFile);
              }
            }

            if (this.currentImages[i]._id != this.coverImageId) {
              businessData.images.push(img)
            }
          }
          // If new images is uploaded
          if (this.newImages.length > 0) {
            businessData.newImages = this.newImages
            console.log(businessData.newImages)
            for (var i = 0; i < businessData.newImages.length; i++) {
              // Get the cropper output for new images
              const { canvas, coordinates } = this.$refs.cropper[i].getResult();
              const preview_file = this.dataURLtoFile(
                canvas.toDataURL(),
                businessData.newImages[i].fileName
              );

              this.formData.append(`new_image_${i}_preview`, preview_file);
              businessData.newImages[i].previewCoordinates = coordinates;
            }
          }

          this.formData.set("business", JSON.stringify(businessData));

          this.$store
            .dispatch("business/UPDATE_BUSINESS", {
              businessID,
              businessData: this.formData,
              query,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Business successfully updated"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.$router.push("/business/ads/list");
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                this.role = null;
                this.business = {
                  name: "",
                  category: null,
                  city: null,
                  location: {
                    address: {
                      line1: "",
                      line2: "",
                    },
                  },
                  email: "",
                  phone: "",
                  description: {
                    long: "",
                    short: "",
                  },
                  images: [],
                  cover: undefined,
                };
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Admin already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating admin user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }

                this.formData = new FormData();
              }

              btnLoader.classList.add("hidden");
              updateBtn
                ? updateBtn.removeAttribute("disabled", "disabled")
                : false;
              approveBtn
                ? approveBtn.removeAttribute("disabled", "disabled")
                : false;
              rejectBtn
                ? rejectBtn.removeAttribute("disabled", "disabled")
                : false;
              updateRejectBtn
                ? updateRejectBtn.removeAttribute("disabled", "disabled")
                : false;
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = TextTrackCueList;
                updateBtn
                  ? updateBtn.removeAttribute("disabled", "disabled")
                  : false;
                approveBtn
                  ? approveBtn.removeAttribute("disabled", "disabled")
                  : false;
                rejectBtn
                  ? rejectBtn.removeAttribute("disabled", "disabled")
                  : false;
                updateRejectBtn
                  ? updateRejectBtn.removeAttribute("disabled", "disabled")
                  : false;
                bodyWrapper.classList.remove("non-clickable");
              });

              this.formData = new FormData();
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords(
              "Please fill up the required fields."
            ),
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });

          btnLoader.classList.add("hidden");
          updateBtn ? updateBtn.removeAttribute("disabled", "disabled") : false;
          approveBtn
            ? approveBtn.removeAttribute("disabled", "disabled")
            : false;
          rejectBtn ? rejectBtn.removeAttribute("disabled", "disabled") : false;
          updateRejectBtn
            ? updateRejectBtn.removeAttribute("disabled", "disabled")
            : false;
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    approveAds(adsID) {
      const adsData = {
        showAd: true,
        adminNote: "",
        status: "approved",
      };
      this.$store
        .dispatch("ads/APPROVE_ADS", { adsID, adsData })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Approve Ads Successfully"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.$router.push("/business/ads/list");
          } else {
            if (res.data.error === "error_user_already_exist") {
              this.$notify({
                message: this.$options.filters.capitalizeWords(
                  "Admin already exists"
                ),
                timeout: 4000,
                icon: "fas fa-exclamation-triangle",
                type: "danger",
              });
            } else {
              this.$notify({
                message: this.$options.filters.capitalizeWords(
                  "Error updating admin user"
                ),
                timeout: 4000,
                icon: "fas fa-exclamation-triangle",
                type: "danger",
              });
            }

            this.formData = new FormData();
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    rejectAds() {
      if (this.rejectClicked) {
        return this.$validator
          .validate("adminNote", this.adminNote)
          .then((valid) => {
            if (valid) {
              const adsData = {
                showAd: false,
                adminNote: this.adminNote,
                status: "rejected",
                featured: null,
              };
              this.$store
                .dispatch("ads/REJECT_ADS", { adsID: this.adsID, adsData })
                .then((res) => {
                  if (res.data.success) {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        "Reject Ads Successfully"
                      ),
                      timeout: 4000,
                      icon: "fas fa-check",
                      type: "success",
                    });
                    this.$router.push("/business/ads/list");
                  } else {
                    if (res.data.error === "error_user_already_exist") {
                      this.$notify({
                        message: this.$options.filters.capitalizeWords(
                          "Admin already exists"
                        ),
                        timeout: 4000,
                        icon: "fas fa-exclamation-triangle",
                        type: "danger",
                      });
                    } else {
                      this.$notify({
                        message: this.$options.filters.capitalizeWords(
                          "Error updating admin user"
                        ),
                        timeout: 4000,
                        icon: "fas fa-exclamation-triangle",
                        type: "danger",
                      });
                    }

                    this.formData = new FormData();
                  }

                  promiseBtnInnerApprove.classList.add("hidden");
                  promiseBtnInnerReject.classList.add("hidden");
                  promiseBtnInner.classList.add("hidden");
                  promiseBtnApprove.removeAttribute("disabled", "disabled");
                  promiseBtnReject.removeAttribute("disabled", "disabled");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                })
                .catch((err) => {
                  console.error(err);
                  err.then((error) => {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        error.errMessage
                      ),
                      timeout: 4000,
                      icon: "fas fa-exclamation-triangle",
                      type: "danger",
                    });
                    this.hasError = true;
                    promiseBtnInnerApprove.classList.add("hidden");
                    promiseBtnInnerReject.classList.add("hidden");
                    promiseBtnApprove.removeAttribute("disabled", "disabled");
                    promiseBtnReject.removeAttribute("disabled", "disabled");
                    bodyWrapper.classList.remove("non-clickable");
                    promiseBtnInner.classList.add("hidden");
                    promiseBtn.removeAttribute("disabled", "disabled");
                  });
                });
            } else {
              this.$notify({
                message: this.$options.filters.capitalizeWords(
                  "Please enter a Reason"
                ),
                timeout: 4000,
                icon: "fas fa-check",
                type: "warning",
              });
            }
          });
      } else {
        this.showReasonField = true;
        this.showApproveButton = false;
        this.rejectClicked = true;
        this.showReasonField = true;
        this.rejectStatus = "";
        this.adminNote = "";
      }
    },
    updateRejectReason(adminNote) {
      return this.$validator
        .validate("reason", this.business.adminNote)
        .then((valid) => {
          if (valid) {
            const adsData = {
              showAd: false,
              adminNote: adminNote,
              status: "rejected",
            };
            this.$store
              .dispatch("ads/REJECT_ADS", { adsID: this.adsID, adsData })
              .then((res) => {
                if (res.data.success) {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Reject Ads Successfully"
                    ),
                    timeout: 4000,
                    icon: "fas fa-check",
                    type: "success",
                  });
                  this.$router.push("/business/ads/list");
                } else {
                  if (res.data.error === "error_user_already_exist") {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        "Admin already exists"
                      ),
                      timeout: 4000,
                      icon: "fas fa-exclamation-triangle",
                      type: "danger",
                    });
                  } else {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        "Error updating admin user"
                      ),
                      timeout: 4000,
                      icon: "fas fa-exclamation-triangle",
                      type: "danger",
                    });
                  }

                  this.formData = new FormData();
                }

                promiseBtnInnerApprove.classList.add("hidden");
                promiseBtnInnerReject.classList.add("hidden");
                promiseBtnInner.classList.add("hidden");
                promiseBtnApprove.removeAttribute("disabled", "disabled");
                promiseBtnReject.removeAttribute("disabled", "disabled");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              })
              .catch((err) => {
                console.error(err);
                err.then((error) => {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      error.errMessage
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  promiseBtnInnerApprove.classList.add("hidden");
                  promiseBtnInnerReject.classList.add("hidden");
                  promiseBtnApprove.removeAttribute("disabled", "disabled");
                  promiseBtnReject.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                });
              });
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Please enter a Reason"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "warning",
            });
          }
        });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
          "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
          false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    onFileChange(e) {
      const { files } = e.target;
      const self = this;

      if (this.allowedNumberOfImages > 1) {
        const total = this.currentImages.length + files.length;

        if (total > this.allowedNumberOfImages) {
          self.newImages = [];
          self.urlImage = [];

          var availableImages = this.allowedNumberOfImages - currentImages;
          if (availableImages == 0) {
            return self.$notify({
              message: `You can't upload more than ${this.allowedNumberOfImages} images.`,
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          } else {
            return self.$notify({
              message: `You can only upload ${availableImages} image(s).`,
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        }
      }

      self.newImages = [];

      for (var i = 0; i < files.length; i++) {
        this.newImages.push({
          index: i,
          fileName: files[i].name,
          image: URL.createObjectURL(files[i]),
        });

        if (this.allowedNumberOfImages == 1) {
          this.formData.append("new_cover", files[0]);
        } else {
          this.formData.append(`new_image_${i}`, files[i]);
        }
      }
    },
    getCommunityCategories() {
      this.$store.dispatch("connections/GET_COMMUNITY_CATEGORY_LIST");
    },
    getSubCategories() {
      this.$store.dispatch(
        "connections/GET_SUB_CATEGORY_LIST",
        this.communityCategory
      );
    },
    getCities() {
      this.$store.dispatch("cities/GET_CITIES", { city: "All" });
    },
  },
  mounted() {
    this.getCities();
    this.getCommunityCategories();
    this.action = this.$route.params.id ? "update" : "create";
    this.adsID = this.$route.params.id;
    this.businessID = this.$route.params.id;
    if (this.$route.params.id) {
      this.getAdsInformation(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss" scoped>
.createAdminContainer select {
  color: #525f7f !important;
}
.progress {
  height: 14px !important;
}
.form-title {
  text-align: center;
  text-transform: uppercase;
}

.preview-image {
  h3 {
    display: block;
    margin: auto;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .cover {
    width: 300px;
    margin: auto;
    img {
      width: 100%;
    }
  }
  .cover-two {
    background: #db9a26;
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
    }
    h3 {
      color: white;
      margin-bottom: 0;
    }
  }
  .images {
    margin: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.form-control {
  color: #525f7f !important;
}

.img-container {
  height: auto;
  margin-bottom: 12px;

  .action {
    display: flex;
    justify-content: center;
    .action-icon {
      margin: 0 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .select-icon {
      color: #0cb799;
    }
    .selected-icon {
      color: var(--primary);
    }
    .delete-icon {
      color: rgba(255, 0, 0, 0.733);
    }
    .update-icon {
      color: royalblue;
    }
    .add-icon {
      color: var(--primary);
      text-align: center;
      border: 1px solid var(--primary);
      padding: 5px 10px;
      margin-top: 25px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: var(--primary);
        color: white;
      }
      p {
        font-size: 15px;
        font-weight: 900;
      }
    }
  }

  .current-img {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    border: 3px solid var(--primary);
    // &:after {
    //   background-image: url('../../assets/images/checked.png');
    //   background-size: 20px 20px;
    //   background-repeat: no-repeat;
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   top: 10px;
    //   right: 10px;
    //   content: '';
    // }

    img {
      opacity: 1;
      display: block;
      margin: auto;
    }
  }
}

.new-image-container {
  h3 {
    text-align: center;
  }
}

.img-title {
  display: block;
  margin: auto;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}

.reason-field {
  border: 2px solid #f4516c;
}

.reason-label {
  text-transform: uppercase;
  font-weight: 900;
  color: #f4516c;
}
.note-important {
  color: red;
}

.small-note {
  color: black;
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}

.instructions {
  color: black;
  font-size: 14px;
  padding-left: 14px;
  margin-bottom: 25px;

  .select-icon {
    color: #0cb799;
  }
  .selected-icon {
    color: var(--primary);
  }
  .delete-icon {
    color: rgba(255, 0, 0, 0.733);
  }
}
</style>
