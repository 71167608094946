var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-4 col-7"},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)])])]),_c('div',{staticClass:"container mt--6 minHeight"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{staticClass:"createConnectionContainer",attrs:{"no-body":"","body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg-10 col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Edit Content")])])]),_c('b-row',{attrs:{"id":"processContentForm"}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pt-0"},[_c('b-row',[_c('form',{staticClass:"w-100"},[_c('div',{staticClass:"p-lg-4 px-lg-4 px-md-4 px-sm-4"},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row form-container"},[_c('div',{staticClass:"col-lg-5"},[_c('h1',[_vm._v("Culture Content")]),_c('br'),_c('label',{staticClass:"w-100 form-control-label pb-2",attrs:{"for":"image"}},[_vm._v("Cover Image")]),(_vm.CropperImageCatcher)?_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.CropperImageCatcher,"stencil-props":{
                                    aspectRatio: _vm.limitations.aspectRatio,
                                  }},on:{"change":_vm.coverOnChange}}):_vm._e(),(
                                    !_vm.CropperImageCatcher && !_vm.culture.cover.url
                                  )?_c('img',{attrs:{"width":"100%","src":require("../../assets/images/img-placeholder.png"),"alt":"","srcset":""}}):_vm._e(),_c('b-form-group',{staticClass:"my-3 has-feedback",attrs:{"role":"group"}},[_c('b-form-file',{staticClass:"form-control no-instruction",class:{
                                      'is-invalid': _vm.imageError,
                                      'has-invalid': _vm.imageError,
                                    },attrs:{"id":"forFeaturedImage","accept":"image/*","data-vv-name":"image","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onFileChange,"ondrop":_vm.onFileChange,"ondragover":_vm.onFileChange}}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.imageError}},[_vm._v(" "+_vm._s(_vm.imageErrorMessage)+" ")])],1),_c('h5',[_c('i',[_vm._v("Please use 4:3 aspect ratio for best results.")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"title"}},[_vm._v("Title ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.culture.title),expression:"culture.title",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('title'),
                                      'has-invalid': _vm.errors.has('title'),
                                    },attrs:{"type":"text","name":"title","data-vv-as":"event title","data-vv-name":"title","placeholder":"Content Title","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.culture.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.culture, "title", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('title')}},[_vm._v(" "+_vm._s(_vm.errors.first("title"))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"description"}},[_vm._v("Description ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.culture.description),expression:"culture.description",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('description'),
                                      'has-invalid':
                                        _vm.errors.has('shortDescription'),
                                    },attrs:{"type":"text","name":"description","data-vv-as":"description","data-vv-name":"description","placeholder":"Description","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.culture.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.culture, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('description')}},[_vm._v(" "+_vm._s(_vm.errors.first("description"))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url"}},[_vm._v("Article Link Url")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.culture.url),expression:"culture.url",modifiers:{"trim":true}}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('urls'),
                                      'has-invalid': _vm.errors.has('urls'),
                                    },attrs:{"type":"text","name":"url","data-vv-as":"aricle url","data-vv-name":"url","placeholder":"https://www.example.com","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.culture.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.culture, "url", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('urls')}},[_vm._v(" "+_vm._s(_vm.errors.first("urls"))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"city"}},[_vm._v("Select City ")]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"\n                                          form-control\n                                          no-instruction\n                                          custom-select\n                                        ",class:{
                                          'is-invalid': _vm.errors.has('city'),
                                          'has-invalid': _vm.errors.has('city'),
                                        },attrs:{"options":_vm.cities,"name":"city","placeholder":"Please select city","data-vv-as":"city","data-vv-name":"city"},model:{value:(_vm.culture.city),callback:function ($$v) {_vm.$set(_vm.culture, "city", $$v)},expression:"culture.city"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('city')}},[_vm._v(" "+_vm._s(_vm.errors.first("city"))+" ")])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"category"}},[_vm._v("Select Content Category ")]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"\n                                          form-control\n                                          no-instruction\n                                          custom-select\n                                        ",class:{
                                          'is-invalid':
                                            _vm.errors.has('category'),
                                          'has-invalid':
                                            _vm.errors.has('category'),
                                        },attrs:{"options":_vm.cultureCategories,"name":"category","placeholder":"Please select category","data-vv-as":"category","data-vv-name":"category"},model:{value:(_vm.contentCategory),callback:function ($$v) {_vm.contentCategory=$$v},expression:"contentCategory"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('category')}},[_vm._v(" "+_vm._s(_vm.errors.first("category"))+" ")])],1)],1)])],1),_c('div',{staticClass:"col-lg-7"},[(_vm.ckeditorReady == false)?_c('b-row',{staticClass:"pt-8"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('pulse-loader',{attrs:{"color":"var(--pulse-loader)"}}),_c('b',[_vm._v("Loading...")])],1)]):_c('b-form-group',{staticClass:"mb-3 has-feedback pt-6",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label pb-2",attrs:{"for":"content"}},[_vm._v("Content")]),(_vm.ckeditorReady)?_c('ckeditor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"no-instruction custom-ckeditor",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('content'),
                                      'has-invalid': _vm.errors.has('content'),
                                    },attrs:{"id":"content","name":"content","editor":_vm.editor,"config":_vm.editorConfig,"data-vv-name":"content","data-vv-as":"content","data-vv-scope":"content"},model:{value:(_vm.culture.content),callback:function ($$v) {_vm.$set(_vm.culture, "content", $$v)},expression:"culture.content"}}):_vm._e(),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('content')}},[_vm._v(" "+_vm._s(_vm.errors.first("content"))+" ")])],1)],1)])])]),_c('div',{staticClass:"row mt-5 px-4"},[_c('div',{staticClass:"col-lg-5 col-md-5 col-12"},[_c('router-link',{ref:"clear",staticClass:"\n                                btn btn-outline-danger\n                                pr-5\n                                pl-5\n                                w-sm-100\n                              ",attrs:{"to":"/culture/content/list","exact":""}},[_vm._v("Cancel")])],1),_c('div',{staticClass:"col-lg-7 col-md-7 col-12"},[_c('button',{staticClass:"\n                                btn btn-primary\n                                float-right\n                                w-sm-100\n                                mb-3 mb-md-0\n                              ",attrs:{"type":"button","id":"processContent"},on:{"click":function($event){return _vm.processContent(_vm.contentID)}}},[_c('b-icon',{staticClass:"btn-loader hidden",attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"1"}}),_vm._v(" "+_vm._s(_vm.action === "create" ? "Create Content" : "Update Content")+" "),_c('span',{staticClass:"promise-btn__spinner-wrapper hidden"},[_c('span',{staticClass:"spinner",staticStyle:{"border-top-color":"rgb(52, 152, 219)","width":"18px","height":"18px","border-width":"4px","animation-duration":"1s"},attrs:{"data-v-cc3e8e04":""}})])],1)])])])])])],1)],1)],1)])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }