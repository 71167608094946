<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createAdminContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Send Notification</h3>
              </div>
            </div>

            <b-row id="processNotification">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row">
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="city"
                                  >Select City
                                </label>
                                <b-form-select
                                  v-model="notification.city.id"
                                  :options="cities"
                                  name="city"
                                  placeholder="Please select city"
                                  v-validate="'required'"
                                  data-vv-as="city"
                                  data-vv-name="city"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  :class="{
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('city'),
                                  }"
                                  @change="setCityName()"
                                >
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('city')"
                                >
                                  {{ errors.first("city") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="title"
                                  >Notification Title
                                </label>
                                <input
                                  type="text"
                                  name="title"
                                  v-model.trim="notification.title"
                                  data-vv-as="title"
                                  v-validate="'required'"
                                  data-vv-name="title"
                                  placeholder="Title"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('title'),
                                    'has-invalid': errors.has('title'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('title')"
                                >
                                  {{ errors.first("title") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="message"
                                  >Notification Message
                                </label>
                                <textarea
                                  id="message"
                                  name="message"
                                  v-model.trim="notification.message"
                                  v-validate="'required'"
                                  data-vv-name="message"
                                  data-vv-as="message"
                                  class="form-control no-instruction long"
                                  placeholder="Enter Message"
                                  maxlength="200"
                                  rows="4"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('message'),
                                    'has-invalid': errors.has('message'),
                                  }"
                                >
                                </textarea>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('message')"
                                >
                                  {{ errors.first("message") }}
                                </div>
                              </b-form-group>

                              <b-form-checkbox
                                switch
                                v-model="redirectToSection"
                                size="lg"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="message"
                                  >Redirect to section
                                </label>
                              </b-form-checkbox>
                            </div>
                            <div v-if="redirectToSection" class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="sections"
                                  >Select section
                                </label>
                                <b-form-select
                                  v-model="notification.section.id"
                                  :options="sections"
                                  name="sections"
                                  placeholder="Select sections"
                                  data-vv-as="sections"
                                  data-vv-name="sections"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  @change="setParentCategories()"
                                >
                                </b-form-select>
                                <!-- <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('sections')">
                                      {{errors.first('sections')}}
                                    </div> -->
                              </b-form-group>

                              <b-form-group
                                v-if="notification.section"
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="parentCategory"
                                  >Select {{ parentCategoryName }} category
                                </label>
                                <b-form-select
                                  v-model="notification.parentCategory.id"
                                  :options="parentCategoryList"
                                  name="parentCategory"
                                  placeholder="Please select parentCategory"
                                  data-vv-as="parentCategory"
                                  data-vv-name="parentCategory"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  @change="setSubCategories()"
                                >
                                </b-form-select>
                                <!-- <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('parentCategory')">
                                      {{errors.first('parentCategory')}}
                                    </div> -->
                              </b-form-group>

                              <b-form-group
                                v-if="
                                  notification.parentCategory &&
                                  subCategoryList.length > 0
                                "
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="subCategory"
                                  >Select sub category
                                </label>
                                <b-form-select
                                  v-model="notification.subCategory.id"
                                  :options="subCategories"
                                  name="subCategory"
                                  placeholder="Please select subCategory"
                                  data-vv-as="sub category"
                                  data-vv-name="subCategory"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  @change="setSubCategoryName()"
                                >
                                </b-form-select>
                                <!-- <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('subCategory')">
                                      {{errors.first('subCategory')}}
                                    </div> -->
                              </b-form-group>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link
                                ref="clear"
                                :to="`/user/admins`"
                                exact
                                class="
                                  btn btn-outline-danger
                                  pr-5
                                  pl-5
                                  w-sm-100
                                "
                                >Cancel</router-link
                              >
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button
                                type="button"
                                id="processNotification"
                                @click="sendNotification()"
                                class="
                                  btn btn-primary
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                "
                              >
                                {{
                                  action === "create" ? "Send Notification" : ""
                                }}
                                <span
                                  class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";

Vue.use(Cookies);

export default {
  data() {
    return {
      action: "create",
      notification: {
        title: "",
        message: "",
        city: {
          id: null,
          name: "",
        },
        section: {
          id: null,
          name: "",
        },
        parentCategory: {
          id: null,
          name: "",
        },
        subCategory: {
          id: null,
          name: "",
        },
      },
      parentCategoryName: "",
      parentCategories: [],
      redirectToSection: false,
    };
  },
  computed: {
    subCategories() {
      let data = [];
      this.subCategoryList.map((subCategory) => {
        data.push({ value: subCategory._id, text: subCategory.title });
      });

      return [
        { value: null, text: "Please select sub category", disabled: true },
        ...data,
      ];
    },
    subCategoryList: {
      get: function () {
        return this.$store.state.messages.subCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    parentCategoryList() {
      let data = [];
      this.parentCategories.map((section) => {
        data.push({ value: section._id, text: section.title });
      });

      return [
        { value: null, text: "Please select section", disabled: true },
        ...data,
      ];
    },
    sections() {
      let data = [];
      let filteredSection = this.sectionList.filter((section) => {
        return section.name != "home";
      });
      filteredSection.map((section) => {
        data.push({ value: section._id, text: section.name.toUpperCase() });
      });

      return [
        { value: null, text: "Please select section", disabled: true },
        ...data,
      ];
    },
    sectionList: {
      get: function () {
        return this.$store.state.messages.sections;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    cities() {
      let data = [];
      let data2 = [];
      let all = this.cityList.filter((city) => city.city == "All");
      let filteredData = this.cityList.filter((city) => city.city != "All");

      all.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      filteredData.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [
        { value: null, text: "Please select city", disabled: true },
        ...data2,
        ...data,
      ];
    },
    cityList: {
      get: function () {
        return this.$store.state.messages.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.messages.loading;
    },
  },
  methods: {
    setCityName() {
      let filteredCity = this.cityList.filter(
        (city) => city._id == this.notification.city.id
      );
      this.notification.city.name = filteredCity[0].city
        .toLowerCase()
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    setSubCategoryName() {
      let filteredSub = this.subCategoryList.filter(
        (sub) => sub._id == this.notification.subCategory.id
      );
      this.notification.subCategory.name = filteredSub[0].title;
    },
    setParentCategories() {
      // Set Section Name
      let filteredSection = this.sectionList.filter(
        (section) => section._id == this.notification.section.id
      );
      this.notification.section.name = filteredSection[0].name;

      // Set Parent Categories
      this.notification.parentCategory.id = null;
      let parentCategories = [];
      let sectionID = this.notification.section.id;
      let filterSection = this.sectionList.filter(
        (section) => section._id === sectionID
      );
      filterSection[0].categories.map((section) =>
        parentCategories.push(section)
      );
      this.parentCategories = parentCategories;
      this.parentCategoryName = filterSection[0].name;
    },
    sendNotification() {
      const promiseBtn = document.getElementById("processNotification");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processNotification");
      bodyWrapper.classList.add("non-clickable");

      let notification = {
        title: this.notification.title,
        message: this.notification.message,
        city: this.notification.city,
        section:
          this.notification.section == null ? "" : this.notification.section,
        category:
          this.notification.parentCategory == null
            ? ""
            : this.notification.parentCategory,
        sub_category:
          this.notification.subCategory == null
            ? ""
            : this.notification.subCategory,
      };

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const messageData = this.$store
            .dispatch("messages/SEND_MESSAGE", notification)
            .then((res) => {
              if (res.data.success) {
                this.notification = {
                  title: "",
                  message: "",
                };

                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Notification successfully sent"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });

                this.$router.push("/notifications");
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Error creating user"
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              }
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });
            });
        } else {
          promiseBtnInner.classList.add("hidden");
          promiseBtn.removeAttribute("disabled", "disabled");
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    getCities() {
      this.$store.dispatch("messages/GET_CITY_LIST");
    },
    getSections() {
      this.$store.dispatch("messages/GET_SECTION_LIST");
    },
    setSubCategories() {
      // Set Parent name
      let filteredParent = this.parentCategories.filter(
        (parent) => parent._id == this.notification.parentCategory.id
      );
      console.log({ filteredParent });
      this.notification.parentCategory.name = filteredParent[0].title;

      // Set Sub Categories
      this.notification.subCategory.id = null;
      this.$store.dispatch(
        "messages/GET_SUB_CATEGORY_LIST",
        this.notification.parentCategory.id
      );
    },
  },
  watch: {},
  mounted() {
    this.getCities();
    this.getSections();
    this.action = this.$route.params.id ? "update" : "create";
  },
};
</script>
<style lang="scss">
</style>
