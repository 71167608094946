<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createAdminContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Update Business</h3>
              </div>
            </div>

            <b-row id="processAdminForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row v-if="loading">
                      <div class="col-lg-12 text-center">
                        <pulse-loader
                          color="var(--pulse-loader)"
                        ></pulse-loader>
                      </div>
                    </b-row>
                    <b-row v-if="!loading">
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <h1 class="form-title mb-5">Business Information</h1>
                          <div class="row px-4">
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="firstname"
                                  >Business Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  v-model.trim="business.name"
                                  data-vv-as="business name"
                                  v-validate="'required'"
                                  data-vv-name="name"
                                  placeholder="Business Name"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('name'),
                                    'has-invalid': errors.has('name'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('name')"
                                >
                                  {{ errors.first("name") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="lastname"
                                  >Business Address</label
                                >
                                <input
                                  type="text"
                                  name="line1"
                                  v-model.trim="business.location.address.line1"
                                  data-vv-as="address line1"
                                  v-validate="'required'"
                                  data-vv-name="line1"
                                  placeholder="Address Line 1"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('line1'),
                                    'has-invalid': errors.has('line1'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('line1')"
                                >
                                  {{ errors.first("line1") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <input
                                  type="text"
                                  name="line2"
                                  v-model.trim="business.location.address.line2"
                                  data-vv-as="address line2"
                                  data-vv-name="line2"
                                  placeholder="Address Line 2"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('line2'),
                                    'has-invalid': errors.has('line2'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('line2')"
                                >
                                  {{ errors.first("line2") }}
                                </div>
                              </b-form-group>

                              <br />
                            </div>
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="email"
                                  >Business Email
                                </label>
                                <input
                                  type="text"
                                  name="businessEmail"
                                  v-model.trim="business.email"
                                  v-validate="'required|email'"
                                  data-vv-name="businessEmail"
                                  data-vv-as="Business Email"
                                  placeholder="Enter Business Email Address"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('businessEmail'),
                                    'has-invalid': errors.has('businessEmail'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('businessEmail')"
                                >
                                  {{ errors.first("businessEmail") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="phone"
                                  >Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phone"
                                  v-model.trim="business.phone"
                                  v-mask="'### ###-####'"
                                  v-validate="{
                                    required: true,
                                    min: 10,
                                    regex:
                                      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                  }"
                                  data-vv-name="phone"
                                  data-vv-as="contact number"
                                  placeholder="Enter Phone Number (123 123-1234)"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('phone'),
                                    'has-invalid': errors.has('phone'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('phone')"
                                >
                                  {{ errors.first("phone") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="lastname"
                                  >Business Website</label
                                >
                                <input
                                  type="text"
                                  name="website"
                                  v-model.trim="business.website"
                                  data-vv-as="business website"
                                  v-validate="'required'"
                                  data-vv-name="website"
                                  placeholder="Ex. https://www.yoursite.com/"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('website'),
                                    'has-invalid': errors.has('website'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('website')"
                                >
                                  {{ errors.first("website") }}
                                </div>
                              </b-form-group>
                            </div>
                          </div>

                          <h1 class="form-title my-5">
                            Business Advertisement
                          </h1>
                          <div class="row px-4">
                            <div class="col-lg-5">
                              <div v-if="adsStatus != undefined">
                                <h3 class="mb-0 mx-auto">
                                  <label for="" class="form-control-label mr-2"
                                    >Status :</label
                                  ><span
                                    :class="{
                                      'bg-success':
                                        this.adsStatus == 'approved',
                                      'bg-warning': this.adsStatus == 'pending',
                                      'bg-danger':
                                        this.adsStatus == 'rejected' ||
                                        this.adsStatus == 'expired',
                                    }"
                                    class="badge badge-pill text-white"
                                    >{{ adsStatus }}</span
                                  >
                                </h3>
                              </div>

                              <div
                                v-if="
                                  (adsStatus == 'rejected' && hasAdminNote) ||
                                  (adsStatus == 'pending' && hasAdminNote)
                                "
                                class="mb-4"
                              >
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="
                                      w-100
                                      form-control-label
                                      reason-label
                                    "
                                    for="longDescription"
                                    >Reason for rejecting
                                  </label>
                                  <textarea
                                    id="longDescription"
                                    name="long"
                                    v-model.trim="business.adminNote"
                                    v-validate="'required'"
                                    data-vv-name="long"
                                    data-vv-as="long description"
                                    placeholder="Enter Long Description"
                                    rows="4"
                                    disabled
                                    class="form-control reason-field"
                                  >
                                  </textarea>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('long')"
                                  >
                                    {{ errors.first("long") }}
                                  </div>
                                </b-form-group>
                              </div>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="short"
                                  >Short Description</label
                                >
                                <input
                                  type="text"
                                  name="short"
                                  v-model.trim="business.description.short"
                                  data-vv-as="short"
                                  v-validate="'required'"
                                  data-vv-name="short"
                                  placeholder="Business Short Description"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('short'),
                                    'has-invalid': errors.has('short'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('short')"
                                >
                                  {{ errors.first("short") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="longDescription"
                                  >Long Description
                                </label>
                                <textarea
                                  id="longDescription"
                                  name="long"
                                  v-model.trim="business.description.long"
                                  v-validate="'required'"
                                  data-vv-name="long"
                                  data-vv-as="long description"
                                  class="form-control no-instruction long"
                                  placeholder="Enter Long Description"
                                  rows="4"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('long'),
                                    'has-invalid': errors.has('long'),
                                  }"
                                >
                                </textarea>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('long')"
                                >
                                  {{ errors.first("long") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="city"
                                  >City
                                </label>
                                <b-form-select
                                  v-model="business.city"
                                  :options="cities"
                                  name="city"
                                  placeholder="Please select city"
                                  v-validate="'required'"
                                  data-vv-as="city"
                                  data-vv-name="city"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  :class="{
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('city'),
                                  }"
                                >
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('city')"
                                >
                                  {{ errors.first("city") }}
                                </div>
                                <div
                                  v-if="city_status == false"
                                  class="small-note"
                                >
                                  Your current city is
                                  <span
                                    class="
                                      title
                                      text-break-all
                                      w-100
                                      text-sm-custom text-center
                                    "
                                    ><span
                                      class="
                                        badge badge-pill
                                        text-white
                                        bg-warning
                                      "
                                      >Hidden</span
                                    ></span
                                  >
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="communityCategory"
                                  >Community Category
                                </label>
                                <b-form-select
                                  v-model="communityCategory"
                                  :options="communityCategories"
                                  name="communityCategory"
                                  placeholder="Please select community category"
                                  v-validate="'required'"
                                  data-vv-as="communityCategory"
                                  data-vv-name="communityCategory"
                                  class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  "
                                  :class="{
                                    'is-invalid':
                                      errors.has('communityCategory'),
                                    'has-invalid':
                                      errors.has('communityCategory'),
                                  }"
                                  @change="getSubCategories()"
                                >
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('communityCategory')"
                                >
                                  {{ errors.first("communityCategory") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                v-if="communityCategory != null"
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <div v-if="subCategories.length > 1">
                                  <label
                                    class="w-100 form-control-label"
                                    for="subCategory"
                                    >Sub Category
                                  </label>
                                  <b-form-select
                                    v-model="business.category"
                                    :options="subCategories"
                                    name="subCategory"
                                    placeholder="Please select subCategory"
                                    v-validate="'required'"
                                    data-vv-as="subCategory"
                                    data-vv-name="subCategory"
                                    class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    "
                                    :class="{
                                      'is-invalid': errors.has('subCategory'),
                                      'has-invalid': errors.has('subCategory'),
                                    }"
                                  >
                                  </b-form-select>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('subCategory')"
                                  >
                                    {{ errors.first("subCategory") }}
                                  </div>
                                </div>
                                <div v-else>
                                  <label
                                    class="w-100 form-control-label error-msg"
                                    for="error"
                                    >No Sub Category Found</label
                                  >
                                </div>
                              </b-form-group>
                            </div>
                            <div class="col-lg-7">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="short"
                                  v-if="editingImage"
                                  >Edit Image</label
                                >
                                <cropper
                                  ref="editImage"
                                  class="cropper"
                                  v-if="editingImage || allowedNumberOfImages == 1"
                                  :src="currentImages[editImageIndex].url"
                                  :stencil-props="{
                                    aspectRatio: limitations.aspectRatio, 
                                  }"
                                  @change="editImageOnChange"
                                />
                              </b-form-group>
                              <div class="preview-image">
                                <div class="cover">
                                  <div class="row">
                                    <h3>Choose Cover</h3>
                                  </div>
                                </div>
                                <ul class="instructions">
                                  <li>Load new images by clicking the <strong>Add Image(s)</strong> button</li>
                                  <li>Choose a cover image by clicking <b-icon icon="check-square-fill" class="action-icon select-icon"></b-icon></li>
                                  <li>Your chosen cover image will show <b-icon icon="check-square-fill" class="action-icon selected-icon"></b-icon></li>
                                  <li>Remove an image by clicking <b-icon icon="x-octagon-fill" class="action-icon delete-icon"></b-icon></li>
                                  <li>Update the crop of an image by clicking on the image</li>
                                  <li class="note-important">Avoid uploading sexually explicit and sexual images.</li>
                                  <li>Please use 4:3 aspect ratio for best results</li>
                                </ul>
                                <div class="images">
                                  <div class="row preview-container">
                                    <!-- Current Image Section -->
                                    <div
                                      v-for="(img, index) in (allowedNumberOfImages > 1 ? currentImages : [])"
                                      :key="img._id"
                                      class="col-lg-3"
                                    >
                                      <div class="img-container editable-image">
                                        <div>
                                          <div class="action">
                                            <div
                                              v-if="coverImageId !== img._id"
                                              class="select-icon"
                                              @click.prevent="
                                                selectMainImage(index, img)
                                              "
                                            >
                                              <b-icon
                                                icon="check-square-fill"
                                                class="action-icon"
                                              ></b-icon>
                                            </div>
                                            <div v-else class="selected-icon">
                                              <b-icon
                                                icon="check-square-fill"
                                                class="action-icon"
                                              ></b-icon>
                                            </div>
                                            <div
                                              v-if="coverImageId !== img._id"
                                              class="delete-icon"
                                              @click.prevent="deleteImage(index, img)"
                                            >
                                              <b-icon
                                                icon="x-octagon-fill"
                                                class="action-icon"
                                              ></b-icon>
                                            </div>
                                          </div>
                                          <div
                                            class="current-img"
                                            :class="{
                                              selected: index == editImageIndex,
                                            }"
                                            @click.prevent="
                                              selectEditImage(index, img)
                                            "
                                          >
                                            <img :src="img.url" alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Add New Image Section -->
                                    <div
                                      v-if="
                                        currentImages.length <
                                          allowedNumberOfImages ||
                                        allowedNumberOfImages == 1
                                      "
                                      :class="[
                                        {
                                          'col-lg-3': allowedNumberOfImages > 1,
                                        },
                                        {
                                          'col-lg-4':
                                            allowedNumberOfImages == 1,
                                        },
                                      ]"
                                    >
                                      <div class="img-container">
                                        <div class="action">
                                          <b-form-file
                                            accept="image/*"
                                            v-model="urlImage"
                                            data-vv-as="uploadImages"
                                            data-vv-name="uploadImages"
                                            ref="fileUpload"
                                            style="display: none"
                                            @change="onFileChange"
                                            @ondrop="onFileChange"
                                            @ondragover="onFileChange"
                                            multiple
                                            placeholder="Choose a file or drop it here..."
                                            drop-placeholder="Drop file here..."
                                          ></b-form-file>
                                          <div
                                            v-if="(currentImages.length + newImages.length) < allowedNumberOfImages || allowedNumberOfImages == 1"
                                            class="add-icon"
                                            @click="triggerUpload()"
                                          >
                                            <p>
                                              {{
                                                allowedNumberOfImages > 1
                                                  ? "Add Image(s)"
                                                  : "Update Image"
                                              }}
                                            </p>
                                            <b-icon
                                              icon="plus-circle-fill"
                                              class="action-icon"
                                            ></b-icon>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 mt-2">
                              <div
                                v-if="newImages.length > 0"
                                class="preview-image"
                              >
                                <div class="cover-two">
                                  <div class="row">
                                    <h3>New Image(s)</h3>
                                  </div>
                                </div>
                                <h5 class="mt-2 mb-4">
                                  <b>NOTE: </b
                                  ><i
                                    >Please use 4:3 aspect ratio for best
                                    results.</i
                                  >
                                </h5>

                                <div class="images mt-2">
                                  <div class="row preview-container">
                                    <!-- Current Image Section -->
                                    <div
                                      v-for="img in newImages"
                                      :key="img.index"
                                      class="col-lg-4"
                                    >
                                      <div class="img-container">
                                        <div>
                                          <div class="current-img">
                                            <cropper
                                              ref="cropper"
                                              class="cropper"
                                              :src="img.image"
                                              :stencil-props="{
                                                aspectRatio:
                                                  limitations.aspectRatio,
                                              }"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link
                                ref="clear"
                                :to="`/client/profile`"
                                exact
                                class="
                                  btn btn-outline-danger
                                  pr-5
                                  pl-5
                                  w-sm-100
                                "
                                >Cancel</router-link
                              >
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button
                                v-if="business.status != 'expired'"
                                type="button"
                                id="processBusinessUpdates"
                                @click="updateBusinessClient()"
                                class="
                                  btn btn-primary
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                "
                              >
                                {{ "Update Business" }}
                                <span
                                  class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import { decoder } from "@/utils/sessions/jwt";
import config from "config";

Vue.use(Cookies);

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      DEFAULT_COVER_ID: 'cover',
      business: {
        name: "",
        category: null,
        location: {
          address: {
            line1: "",
            line2: "",
          },
        },
        city: null,
        email: "",
        phone: "",
        description: {
          long: "",
          short: "",
        },
        images: [],
        cover: undefined,
      },
      city_status: null,
      adsStatus: null,
      action: "create",
      user: {},
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      role: null,
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      passwordFieldType: {
        password: "password",
      },
      iconType: {
        password: "far fa-eye",
      },
      progressWidth: 0,
      progressLabel: "",
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasLowercaseUppercase: false,
      hasSpecialChar: false,
      hasPeriodDash: false,
      hasError: false,
      changePasswordError: "",
      passwordShow: false,
      urlImage: [],
      categories: [
        { value: null, text: "Please select role", disabled: true },
        { value: 1, text: "News" },
        { value: 2, text: "Dining" },
        { value: 3, text: "Arts & Entertainment" },
        { value: 4, text: "Community" },
        { value: 5, text: "Business" },
        { value: 6, text: "Wellness" },
      ],
      formData: new FormData(),
      currentUser: "",
      businessID: "",
      communityCategory: null,
      hasAdminNote: null,
      newImages: [],
      currentImages: [],
      deletedImages: [],
      coverImageId: "",
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      allowedNumberOfImages: null,
      updatedCoverPreview: null,
      updatedCoverCoordinates: null,
      coordinatesSet: false,
      previewFileExtension: "",
      editImageIndex: -1,
      editingImage: false,
    };
  },
  computed: {
    // currentImages() {
    //   return this.business.images.filter((img)=> {
    //     return !this.deletedImages.includes(img._id)
    //   })
    // },
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [
        { value: null, text: "Please select city", disabled: true },
        ...data,
      ];
    },
    cityList: {
      get: function () {
        return this.$store.state.cities.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    communityCategories() {
      let data = [];
      this.communityCategoryList.map((communityCategory) => {
        data.push({
          value: communityCategory._id,
          text: communityCategory.title,
        });
      });

      return [
        {
          value: null,
          text: "Please select community category",
          disabled: true,
        },
        ...data,
      ];
    },
    communityCategoryList: {
      get: function () {
        return this.$store.state.connections.communityCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    subCategories() {
      let data = [];
      this.subCategoryList.map((subCategory) => {
        data.push({ value: subCategory._id, text: subCategory.title });
      });

      return [
        { value: null, text: "Please select sub category", disabled: true },
        ...data,
      ];
    },
    subCategoryList: {
      get: function () {
        return this.$store.state.connections.subCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.admin.loading;
    },
    clientUserList() {
      return this.$store.state.admin.userList.all || null;
    },
  },
  methods: {
    editImageOnChange({ coordinates, canvas }) {
      const { editImage } = this.$refs;
      // Check if cropper is loaded or changed
      if (this.coordinatesSet == false) {
        // If loaded, set coordinates from DB
        editImage.setCoordinates(this.currentImages[this.editImageIndex].previewCoordinates)
        // Set to true if the coordinates is set
        this.coordinatesSet = true;
      } else {
        this.currentImages[this.editImageIndex].previewCoordinates = coordinates
        this.currentImages[this.editImageIndex].preview = null
        this.currentImages[this.editImageIndex].previewFile = this.dataURLtoFile(
          canvas.toDataURL(),
          `${this.convertToSlug(this.business.name)}-${Date.now()}.${
            this.previewFileExtension
          }`
        )
      }
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    makeid(length) {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    dataURLtoFile(dataurl, filename) {
      let name = filename ? filename : this.makeid(10);
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name, { type: mime });
    },
    triggerUpload() {
      this.$refs.fileUpload.$el.childNodes[0].click();
    },
    selectMainImage(index, img) {
      console.log("Select Main Image")
      this.coverImageId = img._id
    },
    selectEditImage(index, img) {
      this.editImageIndex = index
      this.coordinatesSet = false;
      this.editingImage = true;
      this.previewFileExtension = img.url.split(/[#?]/)[0].split(".").pop().trim();
    },
    deleteImage(index, image) {
      const currentEditId = this.editingImage ? this.currentImages[this.editImageIndex]._id : null
      this.currentImages = this.currentImages.filter(i => i._id != image._id);
      this.deletedImages.push(image._id)
      if (this.coverImageId == image._id) {
        this.coverImageId = this.currentImages.length > 0 ? this.currentImages[0]._id : ""
      }
      if (this.editingImage) {
        if (this.editImageIndex == index) {
          this.editingImage = false;
          this.coordinatesSet = false;
          this.editImageIndex = null;
          this.previewFileExtension = null;
        } else {
          this.editImageIndex = this.currentImages.findIndex(i => i._id == currentEditId)
        }
      }
    },
    passwordCheck: function () {
      const password = this.password;
      this.hasNumber = /\d/.test(password);
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasLowercaseUppercase =
        /[a-z]/.test(password) && /[A-Z]/.test(password);
      this.hasSpecialChar = /[!@#/$%^&*)(+=#><;:,._-]/.test(password);
      this.hasPeriodDash = !/^\s*?\.|-.*$/.test(password);
      this.level = 0;

      var progressBar = document.querySelectorAll(".passwordMeter");
      var charCounts = {};
      var numCount = 0;
      var upperCaseCount = 0;
      var specialCharCount = 0;

      for (var i = 0; i < password.length; i++) {
        if (/\d/.test(password.charAt(i))) numCount++;
        if (/[A-Z]/.test(password.charAt(i))) upperCaseCount++;
        if (/[!@#/$%^&*)(+=#><;:,._-]/.test(password.charAt(i)))
          specialCharCount++;
      }

      charCounts.numCount = numCount;
      charCounts.ucCount = upperCaseCount;
      charCounts.scCount = specialCharCount;

      this.progressWidth = this.calcPassStrength(
        password.length,
        this.hasNumber,
        this.hasLowercaseUppercase,
        charCounts,
        this.hasSpecialChar,
        this.hasPeriodDash
      );

      if (this.progressWidth <= 40) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-success");
          el.classList.add("bg-danger");
        });
        this.progressLabel = this.progressWidth >= 30 ? "Bad Password" : "Bad";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth > 40 && this.progressWidth < 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-danger");
          el.classList.remove("bg-success");
          el.classList.add("bg-warning");
        });
        this.progressLabel = "Weak Password";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth >= 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-danger");
          el.classList.add("bg-success");
        });
        this.progressLabel = "Strong Password";

        this.$nextTick(() => {
          this.$validator.errors.remove("password");
        });
      }
    },
    calcPassStrength: function (
      len,
      num,
      lowCap,
      charCounts,
      specialChar,
      periodDash
    ) {
      var level = 0;
      if (len >= 8) {
        level += 18;
      }
      if (charCounts.ucCount === 2) {
        level += 10;
      }
      if (charCounts.ucCount > 2) {
        level += 4;
      }
      if (num) {
        level += 12;
      }
      if (charCounts.numCount > 2) {
        level += 5;
      }
      if (lowCap) {
        level += 22;
      }
      if (specialChar) {
        level += 23;
      }
      if (charCounts.scCount === 2) {
        level += 10;
      }
      if (charCounts.scCount >= 3) {
        level += 12;
      }
      // if (periodDash) { level += 10 }
      if (len === 0) {
        level = 0;
      }
      // console.log(len)
      // console.log(level)
      return level;
    },
    getBusinessInformation() {
      this.$store
        .dispatch("admin/GET_ADMIN_USER_BY_ID", this.currentUser)
        .then((selectedBusiness) => {
          this.business = {
            ...selectedBusiness.business,
            category: selectedBusiness.business.category._id,
            city: selectedBusiness.business.city,
            images: selectedBusiness.business.images || [],
          };
          this.allowedNumberOfImages = selectedBusiness.business.numberOfImages;
          this.city_status = selectedBusiness.business.city_status;
          this.businessID = selectedBusiness.business._id;
          this.adsStatus = selectedBusiness.business.status;
          this.hasAdminNote =
            this.business.adminNote === "" ||
            this.business.adminNote == undefined
              ? false
              : true;
          this.communityCategory = selectedBusiness.business.category.parent;

          this.coverImageId = this.business.cover._id || this.DEFAULT_COVER_ID;
          this.business.cover._id = this.coverImageId;
          this.currentImages = JSON.parse(
            JSON.stringify([{...this.business.cover, _id: this.coverImageId}, ...this.business.images])
          )
          this.getSubCategories();
        });
    },
    processBusinessUpdates(userID) {
      if (this.action === "create") {
        console.log("adding business");
        this.addNewBusinessClient();
      } else {
        console.log("updating business");
        this.updateBusinessClient(userID);
      }
    },
    addNewBusinessClient() {
      const promiseBtn = document.getElementById("processBusinessUpdates");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const user = {
            firstname: this.$options.filters.capitalizeWords(this.firstname),
            lastname: this.$options.filters.capitalizeWords(this.lastname),
            email: this.email.toLowerCase(),
            password: this.password,
            role: this.role,
            business: {
              name: this.business.name,
              category: this.busines.category,
              location: {
                address: {
                  line1: this.business.location.address.line1,
                  line2: this.business.location.address.line2,
                },
              },
              email: this.business.email,
              phone: this.business.phone,
              description: {
                long: this.business.description.long,
                short: this.business.description.short,
              },
            },
          };

          const { email, firstname, lastname, password, role, business } = user;

          console.log(user);

          this.$store
            .dispatch("user/REGISTER_USER", {
              email,
              firstname,
              lastname,
              password,
              role,
              business,
            })
            .then((res) => {
              if (res.data.success) {
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                this.role = null
                this.business = {
                  name: "",
                  category: null,
                  location: {
                    address: {
                      line1: "",
                      line2: "",
                    },
                  },
                  email: "",
                  phone: "",
                  description: {
                    long: "",
                    short: "",
                  },
                  images: undefined,
                  cover: undefined,
                }
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Admin user successfully created"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });

                this.$router.push("/business/list");
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Email already in use"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else if (res.data.error === "Error_creating_business") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Business name already in use"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error creating user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                if (error.errMessage == "error_creating_business") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Business name already in use!"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      error.errMessage
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                }
              });
            });
        } else {
          promiseBtnInner.classList.add("hidden");
          promiseBtn.removeAttribute("disabled", "disabled");
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    updateBusinessClient() {
      const promiseBtn = document.getElementById("processBusinessUpdates");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const businessData = this.business;
          const businessID = this.businessID;

          // The request is from admin
          const query = {
            updatedBy: "Client",
          };

          if (this.deletedImages.length > 0) {
            businessData.deletedImages = this.deletedImages;
            if (this.deletedImages.findIndex(i => i == this.business.cover._id) >= 0) {
              businessData.deleteCover = true
            }
          }

          const coverIndex = this.currentImages.findIndex(i => i._id == this.coverImageId);
          businessData.cover = {
            previewCoordinates: this.currentImages[coverIndex].previewCoordinates,
            url: this.currentImages[coverIndex].url,
            preview: this.currentImages[coverIndex].preview,
            type: this.currentImages[coverIndex].type || 'image',
          }
          
          businessData.images = []
          for (var i = 0; i < this.currentImages.length; i++) {
            let img = {
              _id: this.currentImages[i]._id != this.DEFAULT_COVER_ID ? this.currentImages[i]._id : undefined,
              previewCoordinates: this.currentImages[i].previewCoordinates,
              url: this.currentImages[i].url,
              preview: this.currentImages[i].preview,
            }
            if (this.currentImages[i].previewFile) {
              if (this.currentImages[i]._id == this.coverImageId) {
                this.formData.append('cover_preview', this.currentImages[i].previewFile);
              } else {
                this.formData.append(`image_${businessData.images.length}_preview`, this.currentImages[i].previewFile);
              }
            }

            if (this.currentImages[i]._id != this.coverImageId) {
              businessData.images.push(img)
            }
          }
          // If new images is uploaded
          if (this.newImages.length > 0) {
            businessData.newImages = this.newImages
            console.log(businessData.newImages)
            for (var i = 0; i < businessData.newImages.length; i++) {
              // Get the cropper output for new images
              const { canvas, coordinates } = this.$refs.cropper[i].getResult();
              const preview_file = this.dataURLtoFile(
                canvas.toDataURL(),
                businessData.newImages[i].fileName
              );

              this.formData.append(`new_image_${i}_preview`, preview_file);
              businessData.newImages[i].previewCoordinates = coordinates;
            }
          }

          this.formData.set("business", JSON.stringify(businessData));

          this.$store
            .dispatch("business/UPDATE_BUSINESS", {
              businessID,
              businessData: this.formData,
              query,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Business successfully updated. Wait for admin's approval."
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                // this.$router.push('/client/business')
                location.reload();
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                (this.role = null),
                  (this.business = {
                    name: "",
                    address: {
                      line1: "",
                      line2: "",
                    },
                    email: "",
                    contactNumber: "",
                  });
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Admin already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating admin user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }

                this.formData = new FormData();
              }

              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });

              this.formData = new FormData();
            });
        } else {
        }
      });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
          "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
          false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    onFileChange(e) {
      const { files } = e.target;
      const self = this;

      if (this.allowedNumberOfImages > 1) {
        const total = this.currentImages.length + files.length;

        if (total > this.allowedNumberOfImages) {
          self.newImages = [];
          self.urlImage = [];

          var availableImages = this.allowedNumberOfImages - currentImages;
          if (availableImages == 0) {
            return self.$notify({
              message: `You can't upload more than ${this.allowedNumberOfImages} images.`,
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          } else {
            return self.$notify({
              message: `You can only upload ${availableImages} image(s).`,
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        }
      }

      self.newImages = [];

      for (var i = 0; i < files.length; i++) {
        this.newImages.push({
          index: i,
          fileName: files[i].name,
          image: URL.createObjectURL(files[i]),
        });

        if (this.allowedNumberOfImages == 1) {
          this.formData.append("new_cover", files[0]);
        } else {
          this.formData.append(`new_image_${i}`, files[i]);
        }
      }
    },
    getCommunityCategories() {
      this.$store.dispatch("connections/GET_COMMUNITY_CATEGORY_LIST");
    },
    getSubCategories() {
      this.$store.dispatch(
        "connections/GET_SUB_CATEGORY_LIST",
        this.communityCategory
      );
    },
    getCities() {
      this.$store.dispatch("cities/GET_CITIES", { city: "All" });
    },
  },
  mounted() {
    this.getCities();
    this.getCommunityCategories();
    const token = Cookies.get("user-token");
    const decode = decoder({
      secret: config.secret,
    });
    var payload = decode(token);
    this.currentUser = payload._id;
    this.getBusinessInformation();
  },
};
</script>
<style lang="scss">
.createAdminContainer select {
  color: #525f7f !important;
}
.progress {
  height: 14px !important;
}
.form-title {
  text-align: center;
  text-transform: uppercase;
}

.preview-image {
  h3 {
    display: block;
    margin: auto;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .cover {
    width: 300px;
    margin: auto;
    img {
      width: 100%;
    }
  }
  .cover-two {
    background: #53afff;
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
    }
    h3 {
      color: white;
      margin-bottom: 0;
    }
  }
  .images {
    margin: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.form-control {
  color: #525f7f !important;
}

.img-container {
  height: auto;
  margin-bottom: 12px;

  &.editable-image {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .action {
    display: flex;
    justify-content: center;
    .action-icon {
      margin: 0 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .select-icon {
      color: #0cb799;
    }
    .selected-icon {
      color: var(--primary);
    }
    .delete-icon {
      color: rgba(255, 0, 0, 0.733);
    }
    .add-icon {
      color: var(--primary);
      text-align: center;
      border: 1px solid var(--primary);
      padding: 5px 10px;
      margin-top: 25px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: var(--primary);
        color: black;
      }
      p {
        font-size: 15px;
        font-weight: 900;
      }
    }
  }

  .current-img {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    border: 2px solid var(--primary);
    // &:after {
    //   background-image: url('../../assets/images/checked.png');
    //   background-size: 20px 20px;
    //   background-repeat: no-repeat;
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   top: 10px;
    //   right: 10px;
    //   content: '';
    // }

    img {
      opacity: 1;
      display: block;
      margin: auto;

      border: 1px solid var(--primary-contrast);
    }
  }
}

.new-image-container {
  h3 {
    text-align: center;
  }
}

p.note {
  color: red;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
}

.reason-field {
  border: 2px solid #f4516c;
}

.reason-label {
  text-transform: uppercase;
  font-weight: 900;
  color: #f4516c;
}

.small-note {
  font-weight: 900;
  font-size: 12px;
  font-style: italic;
  margin-top: 5px;
  color: #525f7f !important;
}
.note-important {
  color: red;
}

li {
  list-style: none;
}
.note {
  color: black;
}

.instructions {
  color: black;
  font-size: 14px;
  padding-left: 14px;
  margin-bottom: 25px;

  li {
    list-style: unset;
  }

  .select-icon {
    color: #0cb799;
  }
  .selected-icon {
    color: var(--primary);
  }
  .delete-icon {
    color: rgba(255, 0, 0, 0.733);
  }
}
</style>
