<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createAdminContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Update Business</h3>
              </div>
            </div>

            <b-row id="processAdminForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-row v-if="loading">
                    <div class="col-lg-12 text-center">
                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                    </div>
                  </b-row>
                  <b-card-body class="pt-0" v-else>
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <h1 class="form-title mb-5">Business Information</h1>
                          <div class="row px-4">
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="firstname"
                                  >Business Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  v-model.trim="business.name"
                                  data-vv-as="business name"
                                  v-validate="'required'"
                                  data-vv-name="name"
                                  placeholder="Business Name"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('name'),
                                    'has-invalid': errors.has('name'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('name')"
                                >
                                  {{ errors.first("name") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="lastname"
                                  >Business Address</label
                                >
                                <input
                                  type="text"
                                  name="line1"
                                  v-model.trim="business.location.address.line1"
                                  data-vv-as="address line1"
                                  v-validate="'required'"
                                  data-vv-name="line1"
                                  placeholder="Address Line 1"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('line1'),
                                    'has-invalid': errors.has('line1'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('line1')"
                                >
                                  {{ errors.first("line1") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <input
                                  type="text"
                                  name="line2"
                                  v-model.trim="business.location.address.line2"
                                  data-vv-as="address line2"
                                  data-vv-name="line2"
                                  placeholder="Address Line 2"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('line2'),
                                    'has-invalid': errors.has('line2'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('line2')"
                                >
                                  {{ errors.first("line2") }}
                                </div>
                              </b-form-group>

                              <br />
                            </div>
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="email"
                                  >Business Email
                                </label>
                                <input
                                  type="text"
                                  name="businessEmail"
                                  v-model.trim="business.email"
                                  v-validate="'required|email'"
                                  data-vv-name="businessEmail"
                                  data-vv-as="Business Email"
                                  placeholder="Enter Business Email Address"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('businessEmail'),
                                    'has-invalid': errors.has('businessEmail'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('businessEmail')"
                                >
                                  {{ errors.first("businessEmail") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="phone"
                                  >Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phone"
                                  v-model.trim="business.phone"
                                  v-mask="'### ###-####'"
                                  v-validate="{
                                    required: true,
                                    min: 10,
                                    regex:
                                      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                  }"
                                  data-vv-name="phone"
                                  data-vv-as="contact number"
                                  placeholder="Enter Phone Number (123 123-1234)"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('phone'),
                                    'has-invalid': errors.has('phone'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('phone')"
                                >
                                  {{ errors.first("phone") }}
                                </div>
                              </b-form-group>

                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="lastname"
                                  >Business Website</label
                                >
                                <input
                                  type="text"
                                  name="website"
                                  v-model.trim="business.website"
                                  data-vv-as="business website"
                                  v-validate="'required'"
                                  data-vv-name="website"
                                  placeholder="Ex. https://www.yoursite.com/"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('website'),
                                    'has-invalid': errors.has('website'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('website')"
                                >
                                  {{ errors.first("website") }}
                                </div>
                              </b-form-group>

                              <div class="row mt-5">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <button
                                    type="button"
                                    id="processBusinessUpdates"
                                    @click="processBusinessUpdates(businessID)"
                                    class="
                                      btn btn-primary
                                      float-right
                                      w-sm-100
                                      mb-3 mb-md-0
                                    "
                                  >
                                    <b-icon
                                      icon="arrow-clockwise"
                                      animation="spin"
                                      class="btn-loader hidden"
                                      font-scale="1"
                                    ></b-icon>
                                    {{
                                      action === "create"
                                        ? "Create Business"
                                        : "Update Business"
                                    }}
                                    <span
                                      class="
                                        promise-btn__spinner-wrapper
                                        hidden
                                      "
                                      ><span
                                        data-v-cc3e8e04=""
                                        class="spinner"
                                        style="
                                          border-top-color: rgb(52, 152, 219);
                                          width: 18px;
                                          height: 18px;
                                          border-width: 4px;
                                          animation-duration: 1s;
                                        "
                                      ></span
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br /><br />
                          <h1 class="form-title mb-5">Subscriptions Details</h1>
                          <div class="row px-4">
                            <div class="col-lg-12">
                              <b-table
                                v-if="!loading"
                                class="subscriptionsTable"
                                id="subscriptionsTable"
                                responsive
                                hover
                                :items="[subscriptions]"
                                :fields="ListFields"
                                head-variant="light"
                                :filter-included-fields="['fullName', 'email']"
                              >
                                <template v-slot:cell(status)>
                                  <div class="data">
                                    <div
                                      class="
                                        title
                                        text-break-all
                                        w-100
                                        text-sm-custom text-center
                                      "
                                    >
                                      <span
                                        :class="{
                                          'bg-success':
                                            subscriptions.status === 'active',
                                          'bg-canceled':
                                            subscriptions.status === 'canceled',
                                        }"
                                        class="badge badge-pill text-white"
                                        >{{ subscriptions.status }}</span
                                      >
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:cell(actions) class="data">
                                  <div>
                                    <router-link
                                      :to="`/business/ads/list/edit/${adsID}`"
                                      v-b-tooltip.hover.left="'View Ads'"
                                      class="btn btn-outline-primary btn-sm"
                                    >
                                      <span class="btn-inner--icon"
                                        ><i class="far fa-edit"></i>
                                      </span>
                                    </router-link>
                                    <!-- <a v-if="subscriptions.status != 'canceled'" @click="cancelProcess()" v-b-tooltip.hover.left="'Cancel Business Client Subscription'" class="btn btn-outline-danger btn-sm text-danger">
                                      <span class="btn-inner--icon"><i class="far fa-times-circle"></i> </span>
                                    </a> -->
                                  </div>
                                </template>
                              </b-table>
                            </div>
                          </div>

                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link
                                ref="clear"
                                :to="`/business/list`"
                                exact
                                class="
                                  btn btn-outline-danger
                                  pr-5
                                  pl-5
                                  w-sm-100
                                "
                                >Cancel</router-link
                              >
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import showConfirmation from "@/utils/mixins/cancelNotify";
import moment from "moment";

Vue.use(Cookies);

export default {
  mixins: [showConfirmation],
  data() {
    return {
      business: {
        name: "",
        category: null,
        location: {
          address: {
            line1: "",
            line2: "",
          },
        },
        email: "",
        phone: "",
        description: {
          long: "",
          short: "",
        },
        city: null,
        images: [],
        cover: undefined,
      },
      subscriptions: {
        customer: null,
        subscription: null,
        amount: null,
        interval: null,
        duration: null,
        status: null,
        expirationDate: null,
      },
      adsID: null,
      action: "update",
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      newImages: [],
      newCover: null,
      deletedImages: [],
      urlImage: [],
      categories: [
        { value: null, text: "Please select role", disabled: true },
        { value: 1, text: "News" },
        { value: 2, text: "Dining" },
        { value: 3, text: "Arts & Entertainment" },
        { value: 4, text: "Community" },
        { value: 5, text: "Business" },
        { value: 6, text: "Wellness" },
      ],
      ListFields: [
        {
          key: "customer",
          label: "Customer",
          sortable: true,
        },
        {
          key: "subscription",
          label: "Subscription",
          sortable: true,
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
        },
        {
          key: "interval",
          label: "Interval",
          sortable: true,
        },
        {
          key: "duration",
          label: "Duration",
          sortable: true,
        },
        {
          key: "status",
          label: "Subscription Status",
          sortable: true,
        },
        {
          key: "expirationDate",
          label: "Subscription Expiry Date",
          sortable: true,
        },
        {
          key: "actions",
          label: "actions",
        },
      ],

      formData: new FormData(),
      currentImages: [],
      communityCategory: null,
    };
  },
  computed: {
    // currentImages() {
    //   return this.business.images.filter((img)=> {
    //     return !this.deletedImages.includes(img._id)
    //   })
    // },
    communityCategories() {
      let data = [];
      this.communityCategoryList.map((communityCategory) => {
        data.push({
          value: communityCategory._id,
          text: communityCategory.title,
        });
      });

      return [
        {
          value: null,
          text: "Please select community category",
          disabled: true,
        },
        ...data,
      ];
    },
    communityCategoryList: {
      get: function () {
        return this.$store.state.connections.communityCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    subCategories() {
      let data = [];
      this.subCategoryList.map((subCategory) => {
        data.push({ value: subCategory._id, text: subCategory.title });
      });

      return [
        { value: null, text: "Please select sub category", disabled: true },
        ...data,
      ];
    },
    subCategoryList: {
      get: function () {
        return this.$store.state.connections.subCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.business.loading;
    },
  },
  methods: {
    cancelProcess() {
      const businessID = this.businessID;
      const businessName = this.business.name;
      const businessOwner = this.subscriptions.customer;
      const otherInfo = `<br/>
                          <table class="table table-borderless table-popup">
                            <tr><td><strong>Business Name</strong></td><td>${businessName}</td></tr>
                            <tr><td><strong>Business Owner</strong></td><td>${businessOwner}</td></tr>
                          </table>`;
      this.showConfirmation(
        this.cancelSubscription,
        businessID,
        "this business subscription? Please confirm the details below:",
        otherInfo,
        true
      );
    },
    cancelSubscription() {
      const businessID = this.businessID;
      this.$store
        .dispatch("business/CANCEL_SUBSCRIPTION", businessID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Subscription successfully canceled"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "warning",
            });
            this.$router.push("/business/list");
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error deleting business"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    triggerUpload() {
      this.$refs.fileUpload.$el.childNodes[0].click();
    },
    selectMainImage(img) {
      let image = this.business.images.find((i) => i.url === img.url);
      console.log({ image });
      if (image) {
        image.url = this.business.cover.url;
      } else {
        this.business.images.push({ url: this.business.cover.url });
      }

      this.newCover = image
        ? null
        : this.newImages.find((i) => i.name === img._id);
      this.business.cover.url = img.url;
    },
    deleteImage(image) {
      const imageIndex = this.currentImages.findIndex(
        (i) => i._id === image._id
      );
      const newImageIndex = this.newImages.findIndex(
        (i) => i.name === image._id
      );
      const businessImageIndex = this.business.images.findIndex(
        (i) => i._id === image._id
      );

      if (imageIndex >= 0) {
        this.currentImages.splice(imageIndex, 1);
      }

      if (newImageIndex >= 0) {
        this.newImages.splice(newImageIndex, 1);
      }

      if (businessImageIndex >= 0) {
        this.business.images.splice(businessImageIndex, 1);
        console.log("B_IMAGES:", this.business.images);
      }

      console.log("IMAGE:", image);
    },
    getBusinessClient(businessID) {
      this.$store
        .dispatch("business/GET_CLIENT_USER_BY_ID", businessID)
        .then((selectedUser) => {
          this.business = {
            ...selectedUser.data,
            category: selectedUser.data.category._id,
          };

          this.subscriptions = {
            customer: selectedUser.data.owner.billing.name,
            subscription: selectedUser.data.owner.billing.subscription.nickname,
            amount: selectedUser.data.owner.billing.subscription.amount,
            interval: selectedUser.data.owner.billing.subscription.interval,
            duration:
              selectedUser.data.owner.billing.subscription.interval_count,
            status: selectedUser.data.owner.billing.subscription.status,
            expirationDate: moment(
              selectedUser.data.owner.billing.subscription.nextBilling
            ).format("LL"),
          };

          this.communityCategory = selectedUser.data.category.parent._id;
          this.currentImages = JSON.parse(
            JSON.stringify([this.business.cover, ...this.business.images])
          );
          this.getSubCategories();
        });
    },
    processBusinessUpdates(userID) {
      if (this.action === "create") {
        console.log("adding business");
        this.addNewBusinessClient();
      } else {
        console.log("updating business");
        this.updateBusinessClient(userID);
      }
    },
    // addNewBusinessClient () {
    //   const promiseBtn = document.getElementById('processBusinessUpdates')
    //   const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper')
    //   promiseBtnInner.classList.remove('hidden')
    //   promiseBtn.setAttribute('disabled','disabled')

    //   const bodyWrapper = document.getElementById('processAdminForm')
    //   bodyWrapper.classList.add('non-clickable')

    //   return this.$validator.validateAll().then((isValid) => {
    //     if (isValid) {
    //       const user = {
    //         firstname: this.$options.filters.capitalizeWords(this.firstname),
    //         lastname: this.$options.filters.capitalizeWords(this.lastname),
    //         email: this.email.toLowerCase(),
    //         password: this.password,
    //         role: this.role,
    //         business: {
    //           name: this.business.name,
    //           category: this.busines.category,
    //           location: {
    //             address: {
    //               line1: this.business.location.address.line1,
    //               line2: this.business.location.address.line2
    //             },
    //           },
    //           email: this.business.email,
    //           phone: this.business.phone,
    //           description: {
    //             long: this.business.description.long,
    //             short: this.business.description.short
    //           }
    //         }
    //       }

    //       const {email, firstname, lastname, password, role, business} = user

    //       console.log(user);

    //       this.$store.dispatch('user/REGISTER_USER',  {email, firstname, lastname, password, role, business})
    //         .then((res) => {
    //           if (res.data.success) {
    //             this.user = {}
    //             this.name = ''
    //             this.email = ''
    //             this.password = ''
    //             this.role = null,
    //             this.business = {
    //               name: '',
    //               category: null,
    //               location: {
    //                 address: {
    //                   line1: '',
    //                   line2: ''
    //                 },
    //               },
    //               email: '',
    //               phone: '',
    //               description: {
    //                 long: '',
    //                 short: ''
    //               },
    //               images: undefined,
    //               cover: undefined
    //             },

    //             this.$notify({
    //               message: this.$options.filters.capitalizeWords('Admin user successfully created'),
    //               timeout: 4000,
    //               icon: 'fas fa-check',
    //               type: 'success'
    //             })

    //             this.$router.push('/business/list')
    //           } else {
    //             if (res.data.error === 'error_user_already_exist') {
    //               this.$notify({
    //                 message: this.$options.filters.capitalizeWords('Email already in use'),
    //                 timeout: 4000,
    //                 icon: 'fas fa-exclamation-triangle',
    //                 type: 'danger'
    //               })
    //             } else if(res.data.error === 'Error_creating_business') {
    //               this.$notify({
    //                 message: this.$options.filters.capitalizeWords('Business name already in use'),
    //                 timeout: 4000,
    //                 icon: 'fas fa-exclamation-triangle',
    //                 type: 'danger'
    //               })
    //             } else {
    //               this.$notify({
    //                 message: this.$options.filters.capitalizeWords('Error creating user'),
    //                 timeout: 4000,
    //                 icon: 'fas fa-exclamation-triangle',
    //                 type: 'danger'
    //               })
    //             }
    //           }
    //           promiseBtnInner.classList.add('hidden')
    //           promiseBtn.removeAttribute('disabled','disabled')
    //           bodyWrapper.classList.remove('non-clickable')
    //         }).catch((err) => {
    //           console.error(err)
    //           err.then(error => {
    //             if(error.errMessage == 'error_creating_business') {
    //               this.$notify({
    //                 message: this.$options.filters.capitalizeWords('Business name already in use!'),
    //                 timeout: 4000,
    //                 icon: 'fas fa-exclamation-triangle',
    //                 type: 'danger'
    //               })
    //               this.hasError = true
    //               promiseBtnInner.classList.add('hidden')
    //               promiseBtn.removeAttribute('disabled','disabled')
    //               bodyWrapper.classList.remove('non-clickable')
    //             } else {
    //               this.$notify({
    //                 message: this.$options.filters.capitalizeWords(error.errMessage),
    //                 timeout: 4000,
    //                 icon: 'fas fa-exclamation-triangle',
    //                 type: 'danger'
    //               })
    //               this.hasError = true
    //               promiseBtnInner.classList.add('hidden')
    //               promiseBtn.removeAttribute('disabled','disabled')
    //               bodyWrapper.classList.remove('non-clickable')
    //             }
    //           })
    //         })
    //     } else {
    //       promiseBtnInner.classList.add('hidden')
    //       promiseBtn.removeAttribute('disabled','disabled')
    //       bodyWrapper.classList.remove('non-clickable')
    //     }
    //   })
    // },
    updateBusinessClient() {
      const promiseBtn = document.getElementById("processBusinessUpdates");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      const btnLoader = promiseBtn.querySelector(".btn-loader");
      btnLoader.classList.remove("hidden");
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const businessData = this.business;
          const businessID = this.businessID;
          if (this.newCover) {
            this.formData.set("cover", this.newCover);
          }
          const query = {
            updatedBy: "Admin",
          };

          let nI = this.newImages.filter((image) =>
            this.newCover ? image.name !== this.newCover.name : true
          );
          nI.forEach((image) => this.formData.append("images", image));
          this.formData.set("business", JSON.stringify(businessData));

          this.$store
            .dispatch("business/UPDATE_BUSINESS", {
              businessID,
              businessData: this.formData,
              query,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Business successfully updated"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.$router.push("/business/list");
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                (this.role = null),
                  (this.business = {
                    name: "",
                    address: {
                      line1: "",
                      line2: "",
                    },
                    email: "",
                    contactNumber: "",
                  });
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Admin already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating admin user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }

                this.formData = new FormData();
              }

              btnLoader.classList.add("hidden");
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                btnLoader.classList.add("hidden");
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });

              this.formData = new FormData();
            });
        } else {
        }
      });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
          "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
          false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    onFileChange(e) {
      const { files } = e.target;
      const self = this;
      const currentImages = this.business.images.length;
      const total = currentImages + files.length;

      if (total > 6) {
        self.newImages = [];
        self.urlImage = [];

        return self.$notify({
          message: `You can only upload ${6 - currentImages} image(s).`,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }

      self.newImages = [];

      for (var i = 0; i < files.length; i++) {
        self.newImages.push({
          index: i,
          image: URL.createObjectURL(files[i]),
        });
        self.formData.append("images", files[i]);
      }
      console.log(self.newImages);
    },
    getCommunityCategories() {
      this.$store.dispatch("connections/GET_COMMUNITY_CATEGORY_LIST");
    },
    getSubCategories() {
      this.$store.dispatch(
        "connections/GET_SUB_CATEGORY_LIST",
        this.communityCategory
      );
    },
    getAdsID() {
      this.$store
        .dispatch("ads/GET_BUSINESS_ADS_ID", this.businessID)
        .then((ads) => {
          this.adsID = ads.data[0]._id;
        });
    },
  },
  mounted() {
    this.getCommunityCategories();
    this.action = this.$route.params.id ? "update" : "create";
    this.businessID = this.$route.params.id;
    this.getAdsID();
    if (this.$route.params.id) {
      this.getBusinessClient(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss" scoped>
.createAdminContainer select {
  color: #525f7f !important;
}
.progress {
  height: 14px !important;
}
.form-title {
  text-align: center;
  text-transform: uppercase;
}

.preview-image {
  h3 {
    display: block;
    margin: auto;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .cover {
    width: 300px;
    margin: auto;
    img {
      width: 100%;
    }
  }
  .images {
    margin: auto;
    img {
      width: 100%;
      height: auto;
      // opacity: 0.4;
    }
  }
}
.form-control {
  color: #525f7f !important;
}

.img-container {
  height: auto;
  margin-bottom: 12px;

  .action {
    display: flex;
    justify-content: center;
    .action-icon {
      margin: 0 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .select-icon {
      color: #0cb799;
    }
    .selected-icon {
      color: goldenrod;
    }
    .delete-icon {
      color: rgba(255, 0, 0, 0.733);
    }
    .add-icon {
      color: #53afff;
      text-align: center;
      border: 1px solid #53afff;
      padding: 5px 10px;
      margin-top: 25px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: #53afff;
        color: white;
      }
      p {
        font-size: 15px;
        font-weight: 900;
      }
    }
  }

  .current-img {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    border: 3px solid #db9a26;
    // &:after {
    //   background-image: url('../../assets/images/checked.png');
    //   background-size: 20px 20px;
    //   background-repeat: no-repeat;
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   top: 10px;
    //   right: 10px;
    //   content: '';
    // }

    img {
      opacity: 1;
      display: block;
      margin: auto;
    }
  }
}

.new-image-container {
  h3 {
    text-align: center;
  }
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}
</style>
