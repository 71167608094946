<template>
  <div class="adminList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Connections List</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <router-link
              :to="`/connections/list/create`"
              class="btn btn-primary opacity-8 float-right"
            >
              <i class="fa fa-plus-circle"></i>
              Add New Connection
            </router-link>
          </div>
        </div>
        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-container>
                  <b-row>
                    <b-col class="d-flex">
                      <div class="mr-3 text-nowrap">Connection Status</div>
                      <b-form-select
                        v-model="selectedStatus"
                        :options="statuses"
                        name="Filter"
                        :disabled="loading"
                        class="float-right"
                      />
                    </b-col>
                    <b-col> </b-col>
                    <b-col>
                      <base-input
                        v-model="searchRequest"
                        :disabled="loading"
                        prepend-icon="fas fa-search"
                        placeholder="Search"
                      ></base-input>
                    </b-col>
                  </b-row>
                </b-container>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="connectionsTable"
                  id="connectionsTable"
                  responsive
                  hover
                  :items="connectionList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                  :filter-included-fields="['title', 'city']"
                  @context-changed="loadInitialPendingVisibleItems"
                >
                  <template v-slot:cell(cover)="data">
                    <div class="data eventListCover">
                      <img width="100px" :src="data.item.cover.url" alt="" />
                    </div>
                  </template>
                  <template v-slot:cell(city)="data">
                    <div class="data">
                      <span
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                        >{{ data.item.city }} <br /><span
                          v-if="data.item.city_status == false"
                          class="badge badge-pill text-white bg-warning"
                          >Hidden</span
                        ></span
                      >
                    </div>
                  </template>
                  <template v-slot:cell(createdAt)="data">
                    <div class="data">
                      <span class="title text-break-all w-100 text-sm-custom"
                        >{{ data.item.createdYmd }}<br />
                        {{ data.item.createdHms }}</span
                      >
                    </div>
                  </template>
                  <template v-slot:cell(status)="data">
                    <div class="data">
                      <div
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                      >
                        <span
                          :class="{
                            'bg-success': data.item.status === 'approved',
                            'bg-warning': data.item.status === 'pending',
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.status }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(featured)="data">
                    <div class="data">
                      <div
                        class="
                          title
                          text-break-all
                          w-100
                          text-sm-custom text-center
                        "
                      >
                        <span
                          :class="{
                            'bg-success': data.item.featured == true,
                            'bg-light': data.item.featured == false,
                            'bg-danger': data.item.featured === 'rejected',
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.featured == true ? "Yes" : "No" }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data" class="data">
                    <div>
                      <router-link
                        :to="`list/edit/${data.item.actions}`"
                        v-b-tooltip.hover
                        title="Edit Connection"
                        class="btn btn-outline-primary btn-sm"
                      >
                        <span class="btn-inner--icon"
                          ><i class="far fa-edit"></i>
                        </span>
                      </router-link>
                      <a
                        v-if="data.item.status == 'approved'"
                        @click="
                          setAsFeatured(data.item.featured, data.item.actions)
                        "
                        v-b-tooltip.hover
                        :title="
                          data.item.featured == true
                            ? 'Remove as featured'
                            : 'Set as featured'
                        "
                        class="btn btn-outline-vobo btn-sm text-vobo"
                      >
                        <span class="btn-inner--icon"
                          ><i
                            :class="
                              data.item.featured == true
                                ? 'fas fa-toggle-on'
                                : 'fas fa-toggle-off'
                            "
                          ></i>
                        </span>
                      </a>

                      <a
                        @click="deleteProcess(data.item.actions, data.item)"
                        v-b-tooltip.hover
                        title="Delete Connection"
                        class="btn btn-outline-danger btn-sm text-danger"
                      >
                        <span class="btn-inner--icon"
                          ><i class="far fa-trash-alt"></i>
                        </span>
                      </a>

                      <!-- <a @click="activateAdminUser(data.item.isActive, data.item.actions)" v-b-tooltip.hover :title="data.item.isActive === 1 ? 'Deactivate Admin User': 'Activate Admin User'" class="btn btn-outline-vobo btn-sm text-vobo">
                          <span class="btn-inner--icon"><i :class="data.item.isActive === 1 ? 'fas fa-toggle-on': 'fas fa-toggle-off'"></i> </span>
                        </a> -->
                    </div>
                  </template>
                </b-table>
                <b-row v-if="connectionList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center"
                />
                <div
                  class="float-right"
                  v-if="totalRows > perPage && connectionList.length !== 0"
                >
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Cookies from "js-cookie";
import moment from "moment";
import showConfirmation from "@/utils/mixins/deleteNotify";

Vue.use(Cookies);

export default {
  name: "AdminList",
  mixins: [showConfirmation],
  data: function () {
    return {
      listCollection: [],
      errorFields: [],
      transactionID: "",
      connectionsListFilter: [],
      searchRequest: "",
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      allTimeStatus: false,
      selectedFilter: "all",
      selectedStatus: "all",
      statuses: [
        { value: "all", text: "All" },
        { value: "featured", text: "Featured" },
        { value: "ended", text: "Ended" },
        { value: "ongoing", text: "Ongoing" },
        { value: "upcoming", text: "Upcoming" },
      ],
      sortBy: "dateCreated",
      sortDesc: true,
      ListFields: [
        {
          key: "cover",
          label: "Cover",
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
        },
        {
          key: "city",
          label: "Location",
          sortable: true,
        },
        {
          key: "category",
          label: "Category",
          sortable: true,
        },
        // {
        //   key: 'sub_category',
        //   label: 'Sub Category',
        //   sortable: true
        // },
        // {
        //   key: 'date',
        //   label: 'Date & Time',
        //   sortable: true
        // },
        {
          key: "start",
          label: "Start Date",
          sortable: true,
        },
        {
          key: "end",
          label: "End Date",
          sortable: true,
        },
        {
          key: "time",
          label: "Time",
          sortable: true,
          tdClass: "timeCol",
        },
        {
          key: "featured",
          label: "Featured",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Created",
          sortable: true,
        },
        {
          key: "status",
          label: "status",
          sortable: true,
        },
        {
          key: "actions",
          label: "actions",
          tdClass: "actionsCol",
        },
      ],
      updateAccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.connections.loading;
    },
    connectionList: {
      get: function () {
        return this.connectionsListFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadConnections(currentPage) {
      this.$store.dispatch("connections/GET_CONNECTION_LIST").then((resp) => {
        var filter = "all";
        this.connectionsListFilter =
          this.$store.state.connections.connectionsList[filter];
        this.totalRows = this.connectionsListFilter.length;
      });
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    deleteProcess(id, item) {
      console.log(item);
      const connectionID = String(id).trim();
      const title = String(item.title).trim();
      const location = String(item.city).trim();
      const otherInfo = `<br/>
                          <table class="table table-borderless table-popup">
                            <tr><td><strong>Connection Title</strong></td><td>${title}</td></tr>
                            <tr><td><strong>Connection Location</strong></td><td>${location}</td></tr>
                          </table>`;

      this.showConfirmation(
        this.deleteConnection,
        connectionID,
        "this connection? Please confirm the details below:",
        otherInfo,
        true
      );
    },
    setAsFeatured(currentStatus, id) {
      let formData = new FormData();
      let status = false;
      let statusValue = "";
      const connectionID = String(id).trim();

      if (currentStatus == true) {
        status = false;
        statusValue = "Removed as featured";
      } else {
        status = true;
        statusValue = "Set as featured";
      }

      let connectionData = {
        featured: status,
      };
      formData.set("connection", JSON.stringify(connectionData));
      this.$store
        .dispatch("connections/SET_FEATURED", {
          connectionID,
          connectionData: formData,
        })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Connection content successfully " + statusValue
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadConnections(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error creating connection content"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    deleteConnection(id) {
      const connectionID = String(id).trim();
      this.$store
        .dispatch("connections/DELETE_CONNECTION", connectionID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Connection successfully deleted"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadConnections(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error deleting connection"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    resetPassword(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch("user/RESET_PASSWORD_REQUEST", userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Email sent to user to reset the password"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error resetting user password"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    activateAdminUser(currentStatus, id) {
      let isActive = 0;
      let statusValue = "";
      const userID = String(id).trim();

      if (currentStatus === 1) {
        isActive = 0;
        statusValue = "Deactivated";
      } else {
        isActive = 1;
        statusValue = "Activated";
      }

      const status = { isActive };

      this.$store
        .dispatch("user/UPDATE_USER_STATUS", { userID, status })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Admin user successfully " + statusValue
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadConnections(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error creating user"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.connectionsListFilter =
        this.$store.state.connections.connectionsList[context];
      this.totalRows = this.connectionsListFilter.length;
    },
  },
  mounted() {
    this.loadConnections(this.currentPage);
  },
};
</script>
<style lang="scss">
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}

a.btn-outline-danger:hover {
  color: #fff !important;
}
a.btn-outline-vobo:hover {
  color: #fff !important;
}
a.btn-outline-default:hover {
  color: #fff !important;
}
a.btn-outline-primary:hover {
  color: #fff !important;
}

.timeCol {
  width: 100px;
}

.actionsCol {
  display: flex;
  width: 160px;
}

.text-break-all {
  word-break: break-all;
}

.data.eventListCover {
  img {
    display: block;
    margin: auto;
    object-fit: cover;
    max-height: 75px;
    max-width: 100px;
  }
}

.target-fade-cancel {
  -webkit-animation: target-fade-cancel 2s 1;
  -moz-animation: target-fade-cancel 2s 1;
}

@-webkit-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

@-moz-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

.target-fade-complete {
  -webkit-animation: target-fade-complete 2s 1;
  -moz-animation: target-fade-complete 2s 1;
}

@-webkit-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}

@-moz-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}
</style>
<style lang="scss" sloted>
.actionsCol {
  display: flex;
  width: 140px;
}
</style>
