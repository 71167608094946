<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createAdminContainer"
          >
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Update Your Profile</h3>
              </div>
            </div>

            <b-row id="processAdminForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row v-if="loading">
                      <div class="col-lg-12 text-center">
                        <pulse-loader
                          color="var(--pulse-loader)"
                        ></pulse-loader>
                      </div>
                    </b-row>
                    <b-row v-if="!loading">
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="firstname"
                                  >First Name
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  v-model.trim="firstname"
                                  data-vv-as="first name"
                                  v-validate="'required'"
                                  data-vv-name="firstname"
                                  placeholder="First Name"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('firstname'),
                                    'has-invalid': errors.has('firstname'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('firstname')"
                                >
                                  {{ errors.first("firstname") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="lastname"
                                  >Last Name
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  v-model.trim="lastname"
                                  data-vv-as="last name"
                                  v-validate="'required'"
                                  data-vv-name="lastname"
                                  placeholder="Last Name"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('lastname'),
                                    'has-invalid': errors.has('lastname'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('lastname')"
                                >
                                  {{ errors.first("lastname") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="phoneNumber"
                                  >Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phoneNumber"
                                  v-model.trim="phone"
                                  v-mask="'### ###-####'"
                                  v-validate="{
                                    required: true,
                                    min: 10,
                                    regex:
                                      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                  }"
                                  data-vv-name="phoneNumber"
                                  data-vv-as="contact number"
                                  placeholder="Enter Phone Number (123 123-1234)"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('phoneNumber'),
                                    'has-invalid': errors.has('phoneNumber'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('phoneNumber')"
                                >
                                  {{ errors.first("phoneNumber") }}
                                </div>
                              </b-form-group>
                            </div>
                            <div class="col-lg-6">
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="email"
                                  >Email
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  v-model.trim="email"
                                  v-validate="'required|email'"
                                  data-vv-name="email"
                                  placeholder="Enter Email Address"
                                  autocomplete="NoAutocomplete"
                                  disabled
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('email'),
                                    'has-invalid': errors.has('email'),
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('email')"
                                >
                                  {{ errors.first("email") }}
                                </div>
                              </b-form-group>
                              <b-form-group
                                role="group"
                                class="mb-3 has-feedback"
                              >
                                <label
                                  class="w-100 form-control-label"
                                  for="password"
                                  >Password<small
                                    class="pull-right mr-1"
                                    v-if="passwordShow"
                                    ><a
                                      ><i
                                        @click="switchVisibility('password')"
                                        class="text-gray toggle-visibility"
                                        :class="iconType.password"
                                      ></i></a></small
                                ></label>
                                <input
                                  :type="passwordFieldType.password"
                                  name="password"
                                  v-model.trim="password"
                                  @input="passwordCheck"
                                  data-vv-as="new password"
                                  data-vv-name="password"
                                  data-vv-delay="1000"
                                  placeholder="Enter new password"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('password'),
                                    'has-invalid':
                                      errors.has('password') ||
                                      (password.length > 0 &&
                                        progressWidth <= 40),
                                    'has-valid':
                                      progressWidth >= 75 &&
                                      hasLowercaseUppercase &&
                                      hasNumber &&
                                      hasSpecialChar,
                                    'has-warning':
                                      password.length > 0 &&
                                      progressWidth > 40 &&
                                      progressWidth < 75,
                                  }"
                                />
                                <span class="has-icon-feedback"></span>
                                <div
                                  class="invalid-feedback"
                                  :is-invalid="errors.has('password')"
                                >
                                  {{ errors.first("password") }}
                                </div>
                              </b-form-group>
                              <b-col
                                md="6"
                                class="order-md-last text-default strong"
                              >
                                <small class="font-weight-bold"
                                  >User password must not contain any blank
                                  spaces and meet the following criteria:</small
                                >
                                <ul class="list-icon pt-3">
                                  <li
                                    :class="{
                                      'icon-check-circle-o muted':
                                        password.length == 0,
                                      'icon-check-circle valid':
                                        password.length >= 8,
                                      'icon-warning invalid':
                                        password.length < 8 &&
                                        password.length > 0,
                                    }"
                                  >
                                    8 characters
                                  </li>
                                  <li
                                    :class="{
                                      'icon-check-circle-o muted':
                                        hasLowerCase === false &&
                                        hasUpperCase === false,
                                      'icon-exclamation-circle warning':
                                        (!hasLowerCase && hasUpperCase) ||
                                        (hasUpperCase === false &&
                                          hasLowerCase),
                                      'icon-check-circle valid':
                                        hasLowercaseUppercase,
                                      'icon-warning invalid':
                                        hasLowercaseUppercase === false &&
                                        password.length > 0,
                                    }"
                                  >
                                    Uppercase and lowercase letters
                                  </li>
                                  <li
                                    :class="{
                                      'icon-check-circle-o muted':
                                        password.length == 0,
                                      'icon-check-circle valid': hasNumber,
                                      'icon-warning invalid':
                                        !hasNumber && password.length > 0,
                                    }"
                                  >
                                    Number(s)
                                  </li>
                                  <li
                                    :class="{
                                      'icon-check-circle-o muted':
                                        password.length == 0,
                                      'icon-check-circle valid': hasSpecialChar,
                                      'icon-warning invalid':
                                        !hasSpecialChar && password.length > 0,
                                    }"
                                  >
                                    Special character(s)
                                  </li>
                                </ul>
                                <div class="progress">
                                  <div
                                    class="progress-bar passwordMeter"
                                    role="progressbar"
                                    v-bind:style="{
                                      width: progressWidth + '%',
                                    }"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {{ progressLabel }}
                                  </div>
                                </div>
                              </b-col>
                            </div>
                          </div>

                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link
                                ref="clear"
                                :to="`/client/business`"
                                exact
                                class="
                                  btn btn-outline-danger
                                  pr-5
                                  pl-5
                                  w-sm-100
                                "
                                >Cancel</router-link
                              >
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button
                                type="button"
                                id="procesClientUser"
                                @click="updateClientUser()"
                                class="
                                  btn btn-primary
                                  float-right
                                  w-sm-100
                                  mb-3 mb-md-0
                                "
                              >
                                Save
                                <span
                                  class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import config from "config";
import { decoder } from "@/utils/sessions/jwt";

Vue.use(Cookies);

export default {
  data() {
    return {
      action: "create",
      user: {},
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      passwordFieldType: {
        password: "password",
      },
      iconType: {
        password: "far fa-eye",
      },
      progressWidth: 0,
      progressLabel: "",
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasLowercaseUppercase: false,
      hasSpecialChar: false,
      hasPeriodDash: false,
      hasError: false,
      changePasswordError: "",
      passwordShow: false,
      currentUser: "",
    };
  },
  computed: {
    loading() {
      return this.$store.state.admin.loading;
    },
  },
  methods: {
    passwordCheck: function () {
      const password = this.password;
      this.hasNumber = /\d/.test(password);
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasLowercaseUppercase =
        /[a-z]/.test(password) && /[A-Z]/.test(password);
      this.hasSpecialChar = /[!@#/$%^&*)(+=#><;:,._-]/.test(password);
      this.hasPeriodDash = !/^\s*?\.|-.*$/.test(password);
      this.level = 0;

      var progressBar = document.querySelectorAll(".passwordMeter");
      var charCounts = {};
      var numCount = 0;
      var upperCaseCount = 0;
      var specialCharCount = 0;

      for (var i = 0; i < password.length; i++) {
        if (/\d/.test(password.charAt(i))) numCount++;
        if (/[A-Z]/.test(password.charAt(i))) upperCaseCount++;
        if (/[!@#/$%^&*)(+=#><;:,._-]/.test(password.charAt(i)))
          specialCharCount++;
      }

      charCounts.numCount = numCount;
      charCounts.ucCount = upperCaseCount;
      charCounts.scCount = specialCharCount;

      this.progressWidth = this.calcPassStrength(
        password.length,
        this.hasNumber,
        this.hasLowercaseUppercase,
        charCounts,
        this.hasSpecialChar,
        this.hasPeriodDash
      );

      if (this.progressWidth <= 40) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-success");
          el.classList.add("bg-danger");
        });
        this.progressLabel = this.progressWidth >= 30 ? "Bad Password" : "Bad";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth > 40 && this.progressWidth < 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-danger");
          el.classList.remove("bg-success");
          el.classList.add("bg-warning");
        });
        this.progressLabel = "Weak Password";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth >= 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-danger");
          el.classList.add("bg-success");
        });
        this.progressLabel = "Strong Password";

        this.$nextTick(() => {
          this.$validator.errors.remove("password");
        });
      }
    },
    calcPassStrength: function (
      len,
      num,
      lowCap,
      charCounts,
      specialChar,
      periodDash
    ) {
      var level = 0;
      if (len >= 8) {
        level += 18;
      }
      if (charCounts.ucCount === 2) {
        level += 10;
      }
      if (charCounts.ucCount > 2) {
        level += 4;
      }
      if (num) {
        level += 12;
      }
      if (charCounts.numCount > 2) {
        level += 5;
      }
      if (lowCap) {
        level += 22;
      }
      if (specialChar) {
        level += 23;
      }
      if (charCounts.scCount === 2) {
        level += 10;
      }
      if (charCounts.scCount >= 3) {
        level += 12;
      }
      // if (periodDash) { level += 10 }
      if (len === 0) {
        level = 0;
      }
      // console.log(len)
      // console.log(level)
      return level;
    },
    getClientUser() {
      this.$store
        .dispatch("admin/GET_ADMIN_USER_BY_ID", this.currentUser)
        .then((selectedUser) => {
          this.firstname = selectedUser.firstname;
          this.lastname = selectedUser.lastname;
          this.email = selectedUser.email;
          this.phone = selectedUser.phone;
        });
    },
    updateClientUser() {
      const promiseBtn = document.getElementById("procesClientUser");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const userData = {
            firstname: this.$options.filters.capitalizeWords(this.firstname),
            lastname: this.$options.filters.capitalizeWords(this.lastname),
            email: this.email.toLowerCase(),
            password: this.password,
            phone: this.phone,
            // role: this.role
          };

          if (this.password.length == 0) {
            delete userData.password;
          }

          this.$store
            .dispatch("admin/UPDATE_ADMIN_USER", {
              userID: this.currentUser,
              userData,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message:
                    this.$options.filters.capitalizeWords("Profile saved"),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.getClientUser();
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Email already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating admin user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }

              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              });
            });
        }
      });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
          "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
          false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
  },
  watch: {
    password(value) {
      if (value.length > 0) {
        this.passwordShow = true;
      } else {
        this.passwordShow = false;
      }
    },
  },
  mounted() {
    const token = Cookies.get("user-token");
    const decode = decoder({
      secret: config.secret,
    });
    var payload = decode(token);

    this.currentUser = payload._id;

    this.action = this.$route.params.id ? "update" : "create";
    this.userID = this.$route.params.id;
    this.getClientUser();
  },
};
</script>
<style lang="scss">
.createAdminContainer select {
  color: #525f7f !important;
}
.progress {
  height: 14px !important;
}
</style>
