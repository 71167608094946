<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createAdminContainer">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-11 col-8">
                <h3 class="mb-0">View Advertisement</h3>
              </div>
            </div>
            <b-row v-if="loading">
              <div class="col-lg-12 text-center">
                <pulse-loader color="var(--pulse-loader)"></pulse-loader>
              </div>
            </b-row>


            <b-row v-else id="processAdvertisementForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">

                            <div class="col-lg-5">
                              <div v-if="advertisementStatus != undefined">
                                <h3 class="mb-2 mx-auto">
                                  <label for="" class="form-control-label mr-2">Status : </label><span :class="{
                                    'bg-success':
                                      this.advertisementStatus == 'approved',
                                    'bg-warning':
                                      this.advertisementStatus == 'pending',
                                    'bg-danger':
                                      this.advertisementStatus ==
                                      'rejected' ||
                                      this.advertisementStatus == 'expired',
                                  }" class="badge badge-pill text-white">{{ advertisementStatus }}</span>
                                </h3>
                              </div>
                              <div v-if="
                                (advertisementStatus == 'rejected' &&
                                  hasAdminNote) ||
                                (advertisementStatus == 'pending' &&
                                  hasAdminNote)
                              " class="mb-5">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="
                                      w-100
                                      form-control-label
                                      reason-label
                                    " for="reason">Reason for rejecting
                                  </label>
                                  <textarea id="reason" name="reason" v-model.trim="advertisement.adminNote"
                                    v-validate="'required'" data-vv-name="reason" data-vv-as="reason"
                                    placeholder="Enter Reason for rejecting" maxlength="200" rows="4"
                                    class="form-control reason-field" :class="{
                                      input: true,
                                      'is-invalid': errors.has('reason'),
                                      'has-invalid': errors.has('reason'),
                                    }">
                                  </textarea>
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('reason')">
                                    {{ errors.first("reason") }}
                                  </div>
                                </b-form-group>
                              </div>

                              <!-- START OF CREATE FORM -->
                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="buisnessName">Business Name</label>
                                <input type="text" name="buisnessName" v-model.trim="advertisement.buisnessName"
                                  data-vv-as="buisnessName" v-validate="'required'" data-vv-name="buisnessName"
                                  placeholder="Business Name" autocomplete="NoAutocomplete"
                                  class="form-control no-instruction" :class="{
                                    input: true,
                                    'is-invalid': errors.has('buisnessName'),
                                    'has-invalid': errors.has('buisnessName'),
                                  }" />
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('buisnessName')">
                                  {{ errors.first("buisnessName") }}
                                </div>
                              </b-form-group>

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="businessWebsite">Business Website
                                </label>
                                <input id="businessWebsite" type="text" name="businessWebsite"
                                  v-model.trim="advertisement.website" v-validate="'required'"
                                  data-vv-name="businessWebsite" data-vv-as="businessWebsite"
                                  class="form-control no-instruction long" placeholder="Enter Business Website" rows="4"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('businessWebsite'),
                                    'has-invalid': errors.has('businessWebsite'),
                                  }" />

                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('businessWebsite')">
                                  {{ errors.first("businessWebsite") }}
                                </div>
                              </b-form-group>

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="businessEmail">Business Email
                                </label>
                                <input type="text" name="businessEmail" v-model.trim="advertisement.businessEmail"
                                  v-validate="'required|email'" data-vv-name="businessEmail" data-vv-as="Business Email"
                                  placeholder="Enter Business Email Address" autocomplete="section-business email"
                                  class="form-control no-instruction" :class="{
                                    input: true,
                                    'is-invalid': errors.has('businessEmail'),
                                    'has-invalid':
                                      errors.has('businessEmail'),
                                  }" />
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('businessEmail')">
                                  {{ errors.first("businessEmail") }}
                                </div>
                              </b-form-group>

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="phone">Business Phone Number
                                </label>
                                <input type="text" name="businessContact"
                                  v-model.trim="advertisement.businessPhoneNumber" v-mask="'### ###-####'" v-validate="{
                                    required: true,
                                    min: 10,
                                    regex:
                                      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                  }" data-vv-name="businessContact" data-vv-as="Business Phone Number"
                                  placeholder="Enter Business Phone Number (123 123-1234)"
                                  autocomplete="section-business tel" class="form-control no-instruction" :class="{
                                    input: true,
                                    'is-invalid':
                                      errors.has('businessContact'),
                                    'has-invalid':
                                      errors.has('businessContact'),
                                  }" />
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('businessContact')">
                                  {{ errors.first("businessContact") }}
                                </div>
                              </b-form-group>

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="lastname">Business Address</label>
                                <input type="text" name="line1" v-model.trim="advertisement.businessAddress"
                                  data-vv-as="Address Line 1" v-validate="'required'" data-vv-name="line1"
                                  placeholder="Address Line 1" autocomplete="section-business address-line1"
                                  class="form-control no-instruction" :class="{
                                    input: true,
                                    'is-invalid': errors.has('line1'),
                                    'has-invalid': errors.has('line1'),
                                  }" />
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('line1')">
                                  {{ errors.first("line1") }}
                                </div>
                              </b-form-group>

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <input type="text" name="line2" v-model.trim="advertisement.businessAddressLine2"
                                  data-vv-as="Address Line 2" data-vv-name="line2" placeholder="Address Line 2"
                                  autocomplete="section-business address-line2" class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('line2'),
                                    'has-invalid': errors.has('line2'),
                                  }" />
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('line2')">
                                  {{ errors.first("line2") }}
                                </div>
                              </b-form-group>
                            </div>

                            <div class="col-lg-5">
                              <!-- START OF CREATE FORM -->

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="page">Select Page
                                </label>
                                <b-form-select v-model="page" :options="pages" name="page"
                                  placeholder="Please select page" v-validate="'required'" data-vv-as="Page"
                                  data-vv-name="page" class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  " :class="{
                                    'is-invalid': errors.has('page'),
                                    'has-invalid': errors.has('page'),
                                  }" @change="selectPage()">
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('page')">
                                  {{ errors.first("page") }}
                                </div>
                              </b-form-group>
                              <b-form-group v-if="page != null && this.page === '602cc007e5ba430015dbc5f6'" role="group" class="mb-3 has-feedback">
                                <div v-if="categories.length > 1">
                                  <label class="w-100 form-control-label" for="category">Select Category (Optional)
                                  </label>
                                  <b-form-select v-model="category" :options="categories" name="category"
                                    placeholder="Please select category" class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    ">
                                  </b-form-select>
                                </div>
                              </b-form-group>
                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="city">Select City
                                </label>
                                <b-form-select v-model="advertisement.city" :options="cities" name="city"
                                  placeholder="Please select city" v-validate="'required'" data-vv-as="city"
                                  data-vv-name="city" class="
                                    form-control
                                    no-instruction
                                    custom-select
                                  " :class="{
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('city'),
                                  }">
                                </b-form-select>
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('city')">
                                  {{ errors.first("city") }}
                                </div>
                              </b-form-group>

                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="datepicker">Select when to start ad
                                  campaign</label>
                                <b-form-datepicker id="datepicker" :min="new Date()" value-as-date
                                  v-model="selectedDate" class="mb-2">
                                </b-form-datepicker>
                              </b-form-group>

                              <div class="">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="adDuration">Enter ad duration
                                    (Months)</label>
                                  <input type="number" name="adDuration" v-model.trim="advertisement.adDuration"
                                    data-vv-as="Ad Duration (Months)" v-validate="'required|min_value:0'"
                                    data-vv-name="adDuration" placeholder="Ad Duration (Months)"
                                    class="form-control no-instruction" :class="{
                                      input: true,
                                      'is-invalid': errors.has('adDuration'),
                                      'has-invalid': errors.has('adDuration'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('adDuration')">
                                    {{ errors.first("adDuration") }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                          </div>

                          <!-- image upload -->
                            <b-form-group role="group" class="mb-3 has-feedback">
                              <label class="w-100 form-control-label" for="uploadImages">Upload Images</label>
                              <b-form-file accept="image/*" v-model="urlImage" data-vv-as="Images"
                                data-vv-name="uploadImages" @change="onFileChange"
                                @ondrop="onFileChange" @ondragover="onFileChange" :state="!hasImageError"
                                placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                                class="upload-img-field" :class="{
                                  'is-invalid': hasImageError,
                                  'has-invalid': hasImageError,
                                }"></b-form-file>
                              <div v-if="hasImageError" class="image-error-msg">
                                {{ imageError }}
                              </div>
                            </b-form-group>
                            <h5 class="mt-3">
                              <i>For best display results, make sure to
                                upload an image with a 4:3 aspect ratio.
                                Minimum recommended size is 1024x576</i>
                            </h5>

                            <div v-if="business.images.otherImage != 0" class="preview-images">
                              <h2>Select Cover Image</h2>
                              <div class="row preview-container">
                                <div v-for="img in business.images.otherImage" :key="img.index" class="mb-5" :class="[
                                  {
                                    'col-lg-4':
                                      business.images.otherImage.length >
                                      1,
                                  },
                                  {
                                    'col-lg-6':
                                      business.images.otherImage.length ==
                                      1,
                                  },
                                ]">
                                  <cropper ref="cropper" class="cropper" :class="[
                                    {
                                      selected:
                                        img.index ==
                                        business.images.mainImage,
                                    },
                                  ]" :src="img.image" :stencil-props="{ aspectRatio: limitations.aspectRatio }" />


                                  <div class="select-container">
                                    <div v-if="
                                      img.index !=
                                      business.images.mainImage
                                    " class="btn btn-theme" @click="selectMainImage(img.index)">
                                      Select As Cover

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          <!-- footer buttons -->

                          <div class="row px-4">
                            <div class="col-lg-12">
                              <div class="row mt-4 px-4">
                                <div class="col-lg-6 col-md-4 col-12">
                                  <router-link ref="clear" :to="`/advertisement/list`" exact class="
                                      btn btn-outline-danger
                                      pr-5
                                      pl-5
                                      w-sm-100
                                    ">Cancel</router-link>
                                  <button v-if="advertisement.status != 'expired'" type="button"
                                    id="processAdvertisementUpdates" @click="
                                      processAdvertisementUpdates(advertisementID)
                                    " class="
                                      btn btn-primary
                                      w-sm-100
                                      mb-3 mb-md-0
                                      mx-2
                                    ">
                                    <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden"
                                      font-scale="1"></b-icon>
                                    {{
                                        action === "create"
                                          ? "Create Advertisement"
                                          : "Update Advertisement"
                                    }}
                                    <span class="promise-btn__spinner-wrapper"><span data-v-cc3e8e04="" class="spinner"
                                        style="
                                          border-top-color: rgb(52, 152, 219);
                                          width: 18px;
                                          height: 18px;
                                          border-width: 4px;
                                          animation-duration: 1s;
                                        "></span></span>
                                    <span class="
                                        promise-btn__spinner-wrapper
                                        update
                                        hidden
                                      "><span data-v-cc3e8e04="" class="spinner" style="
                                          border-top-color: rgb(52, 152, 219);
                                          width: 18px;
                                          height: 18px;
                                          border-width: 4px;
                                          animation-duration: 1s;
                                        "></span></span>
                                  </button>
                                </div>
                                <!-- {{ () }} -->
                                <div class="col-lg-6 col-md-4 col-12">
                                  <button v-if="
                                    (advertisementStatus == 'rejected' &&
                                      hasAdminNote) ||
                                    (advertisementStatus == 'pending' &&
                                      hasAdminNote &&
                                      advertisement.status != 'expired')
                                  " type="button" id="updateRejectBtn" @click="
  updateRejectReason(advertisement.adminNote)
" class="
                                      btn btn-danger
                                      float-right
                                      w-sm-100
                                      mb-3 mb-md-0
                                      mx-2
                                    ">
                                    Update Reason
                                    <span class="
                                        promise-btn__spinner-wrapper
                                        reject
                                        hidden
                                      "><span data-v-cc3e8e04="" class="spinner" style="
                                          border-top-color: rgb(52, 152, 219);
                                          width: 18px;
                                          height: 18px;
                                          border-width: 4px;
                                          animation-duration: 1s;
                                        "></span></span>
                                  </button>

                                  <button v-if="
                                    (advertisementStatus == 'rejected' &&
                                      !hasAdminNote) ||
                                    (advertisementStatus == 'pending' &&
                                      !hasAdminNote) ||
                                    (advertisementStatus == 'approved' &&
                                      advertisement.status != 'expired')
                                  " type="button" id="rejectBtn" @click="rejectAdvertisement()" class="
                                      btn btn-danger
                                      float-right
                                      w-sm-100
                                      mb-3 mb-md-0
                                      mx-2
                                    ">
                                    {{ rejectClicked ? "Submit" : "Reject" }}
                                    <span class="
                                        promise-btn__spinner-wrapper
                                        reject
                                        hidden
                                      "><span data-v-cc3e8e04="" class="spinner" style="
                                          border-top-color: rgb(52, 152, 219);
                                          width: 18px;
                                          height: 18px;
                                          border-width: 4px;
                                          animation-duration: 1s;
                                        "></span></span>
                                  </button>

                                  <div v-if="showReasonField">
                                    <b-form-group role="group" class="mb-3 has-feedback">
                                      <input type="text" name="adminNote" v-model.trim="adminNote"
                                        v-validate="'required'" data-vv-as="Reason for rejecting"
                                        data-vv-name="adminNote" placeholder="Reason for rejecting"
                                        autocomplete="NoAutocomplete" class="form-control no-instruction" :class="{
                                          input: true,
                                          'is-invalid': errors.has('adminNote'),
                                          'has-invalid': errors.has('adminNote'),
                                        }" />
                                      <span class="has-icon-feedback"></span>
                                      <div class="invalid-feedback" :is-invalid="errors.has('adminNote')">
                                        {{ errors.first("adminNote") }}
                                      </div>
                                    </b-form-group>
                                  </div>
                                  <button v-if="
                                    showApproveButton &&
                                    action !== 'create' &&
                                    advertisementStatus != 'approved' &&
                                    advertisement.status != 'expired'
                                  " type="button" id="approveBtn" @click="approveAdvertisement(advertisementID)" class="
                                      btn btn-primary
                                      float-right
                                      w-sm-100
                                      mb-3 mb-md-0
                                      mx-2
                                    ">
                                    Approve
                                    <span class="
                                        promise-btn__spinner-wrapper
                                        approve
                                        hidden
                                      "><span data-v-cc3e8e04="" class="spinner" style="
                                          border-top-color: rgb(52, 152, 219);
                                          width: 18px;
                                          height: 18px;
                                          border-width: 4px;
                                          animation-duration: 1s;
                                        "></span></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>



          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import showConfirmation from "@/utils/mixins/rejectNotify";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

Vue.use(Cookies);

export default {
  name: "AdvertisementForm",
  mixins: [showConfirmation],
  components: {
    Cropper,
  },
  data() {
    return {
      advertisement: {
        buisnessName: "",
        page: null,
        category: null,
        city: null,
        businessAddress: "",
        businessAddressLine2: "",
        businessPhoneNumber: "",
        businessEmail: "",
        website: "",
        adStartDate: null,
        adDuration: null,
        plan: null,
      },
      business: {
        images: {
          mainImage: 0,
          otherImage: [],
        },
      },
      cover: null,
      hasImageError: null,
      selectedDate: null,
      advertisementID: "",
      showAdvertisement: "",
      allowedImages: 1,
      advertisementStatus: null,
      adminNote: "",
      hasAdminNote: false,
      showReasonField: false,
      showApproveButton: true,
      rejectClicked: false,
      action: "update",
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      newImages: [],
      newCover: null,
      deletedImages: [],
      urlImage: [],
      formData: new FormData(),
      page: null,
      category: null,
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      allowedNumberOfImages: null,
      newPreviewImage: null,
      updatedCoverPreview: null,
      updatedCoverCoordinates: null,
      coordinatesSet: false,
      coordinatesChanges: false,
      previewFileExtension: "",
    };
  },
  computed: {
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [
        { value: null, text: "Please select city", disabled: true },
        ...data,
      ];
    },
    pages() {
      return [
        { text: "Please select page", value: null, disabled: true },
        { text: "Home", value: "6038d862a7ba0d268faeadfd" },
        { text: "Culture", value: "602cc007e5ba430015dbc5f4" },
        { text: "Connection", value: "602cc007e5ba430015dbc5f5" },
        { text: "Community", value: "602cc007e5ba430015dbc5f6" },
      ];
    },
    adPricingPlans() {
      return [
        { value: null, text: "Please select plan", disabled: true },
        { text: "Plan 1", value: "602cc007e5ba430015dbc5f6" },
        { text: "Plan 2", value: "602cc007e5ba430015dbc5f5" },
      ];
    },
    cityList: {
      get: function () {
        return this.$store.state.cities.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    categories() {
      let data = [];
      this.categoryList.map((category) => {
        data.push({ value: category._id, text: category.title });
      });

      return [
        { value: null, text: "Please select category", disabled: true },
        { value: null, text: "" },
        ...data,
      ];
    },
    categoryList: {
      get: function () {
        return this.$store.state.advertisement.advertisementCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.advertisement.loading;
    },
  },
  methods: {
    coverOnChange({ coordinates, canvas }) {
      const { cover } = this.$refs;
      // Check if cropper is loaded or changed
      if (this.coordinatesSet == false) {
        // If loaded, set coordinates from DB

        if (this.advertisement.cover.previewCoordinates) {
          cover.setCoordinates(this.advertisement.cover.previewCoordinates);
        }
        // Set to true if the coordinates is set
        this.coordinatesSet = true;
      } else {
        // If the cropper is changed, store the coordinates and set
        this.coordinatesChanges = true;
        this.advertisement.cover.previewCoordinates = coordinates;
      }

      // Get the cropped image and coordinates
      this.updatedCoverPreview = this.dataURLtoFile(
        canvas.toDataURL(),
        `${this.convertToSlug(this.advertisement.name)}-preview.${this.previewFileExtension
        }`
      );
      this.updatedCoverCoordinates = coordinates;
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    makeid(length) {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    dataURLtoFile(dataurl, filename) {
      let name = filename ? filename : this.makeid(10);
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name, { type: mime });
    },
    selectPage() {
      // this.page = null;
      this.category = null;
      this.getCategories();
    },
    triggerUpload() {
      this.$refs.fileUpload.$el.childNodes[0].click();
    },
    selectMainImage(index) {
      this.business.images.mainImage = index;
    },
    getAdvertisementInformation(advertisementID) {
      this.$store
        .dispatch("advertisement/GET_ADVERTISEMENT_BY_ID", advertisementID)
        .then((selectedAdvertisement) => {
          this.advertisement.buisnessName = selectedAdvertisement.business.name;
          this.advertisement.businessAddress = selectedAdvertisement.business.address;
          this.advertisement.businessAddressLine2 = selectedAdvertisement.business.addressLine2;
          this.advertisement.website = selectedAdvertisement.business.website;
          this.advertisement.businessEmail = selectedAdvertisement.business.email;
          this.advertisement.businessPhoneNumber = selectedAdvertisement.business.phoneNumber;
          this.advertisement.city = selectedAdvertisement.city;
          this.page = selectedAdvertisement.page;
          this.category = selectedAdvertisement.category;
          this.selectedDate = selectedAdvertisement.adStartDate;
          this.advertisement.adDuration = selectedAdvertisement.adDuration;
          this.advertisementStatus = selectedAdvertisement.status;
          this.cover = selectedAdvertisement.cover;
          // this.urlImage = selectedAdvertisement.cover.url;

          this.business.images.otherImage[0] = {
            index: 1,
            fileName: selectedAdvertisement.cover.url,
            image: selectedAdvertisement.cover.url,
          }
          this.getCategories();
          // Load to Cropper
          this.advertisement.cover = selectedAdvertisement.cover || null
          this.updatedCoverPreview =
            selectedAdvertisement.cover.url;
          this.previewFileExtension =
            selectedAdvertisement.cover.url
              .split(/[#?]/)[0]
              .split(".")
              .pop()
              .trim();
        });
    },
    processAdvertisementUpdates(advertisementID) {
      if (this.action === "create") {
        this.addNewAdvertisement();
      } else {
        this.updateAdvertisement(advertisementID);
      }
    },
    addNewAdvertisement() {
      const bodyWrapper = document.getElementById("processAdvertisementForm");
      const updateBtn = document.getElementById("processAdvertisementUpdates");
      const approveBtn = document.getElementById("approveBtn");
      const rejectBtn = document.getElementById("rejectBtn");
      const updateRejectBtn = document.getElementById("updateRejectBtn");
      const btnLoader = updateBtn.querySelector(".btn-loader");

      btnLoader.classList.remove("hidden");
      bodyWrapper.classList.add("non-clickable");
      updateBtn.setAttribute("disabled", "disabled");
      approveBtn ? approveBtn.setAttribute("disabled", "disabled") : false;
      rejectBtn ? rejectBtn.setAttribute("disabled", "disabled") : false;
      updateRejectBtn
        ? updateRejectBtn.setAttribute("disabled", "disabled")
        : false;

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let advertisementData = this.advertisement;
          advertisementData.newImagesCoordinates = [];
          advertisementData.newCoverCoordinates = [];
          const advertisementID = this.advertisementID;
          advertisementData.showAd = this.showAdvertisement;
          advertisementData.page = this.page;
          advertisementData.category = this.category;
          // Prevent overwriting adminNote
          delete advertisementData.adminNote;
          // The request is from admin
          const query = {
            updatedBy: "Admin",
            updatingAdvertisement: true,
          };

          const formData = new FormData()

          if (this.business.images.otherImage.length == 0) {
            this.hasImageError = true;
            this.imageError = "Please upload images";
          }

          let user = {
            id: this.advertisementID,
            business: {
              name: this.advertisement.buisnessName,
              location: {
                address: {
                  line1: this.advertisement.businessAddress,
                  line2: this.advertisement.businessAddressLine2
                }
              },
              website: this.advertisement.website,
              email: this.advertisement.businessEmail,
              phone: this.advertisement.businessPhoneNumber,
              images: this.business.images,

            },
            advertisement: {
              city: this.advertisement.city,
              page: this.page,
              category: this.category
            },
            billing: {
              address: {
                address: [1, 2],
                city: '11',
                state: '11',
                zipCode: 'required',
                paymentMethod: {},
                card: {},
              },
            },
            selectedDate: this.selectedDate,
            adDuration: this.advertisement.adDuration,
            preview_coordinates: {}
          };

          // Send Cropper Image to API
          for (var i = 0; i < this.business.images.otherImage.length; i++) {
            const { canvas, coordinates } = this.$refs.cropper[i].getResult();
            const cover = this.dataURLtoFile(
              canvas.toDataURL(),
              this.business.images.otherImage[i].fileName
            );
            user.preview_coordinates = coordinates;
            formData.append("images_preview", cover);
          }
          formData.set("ad", JSON.stringify(user));


          this.$store
            .dispatch("advertisement/CREATE_ADVERTISEMENT", formData)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Advertisement successfully created"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.$router.push("/advertisement/list");
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                (this.role = null),
                  (this.advertisement = {
                    name: "",
                    address: {
                      line1: "",
                      line2: "",
                    },
                    email: "",
                    contactNumber: "",
                  });
              } else {

                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Admin already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating admin user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }

                this.formData = new FormData();
              }

              btnLoader.classList.add("hidden");
              updateBtn
                ? updateBtn.removeAttribute("disabled", "disabled")
                : false;
              approveBtn
                ? approveBtn.removeAttribute("disabled", "disabled")
                : false;
              rejectBtn
                ? rejectBtn.removeAttribute("disabled", "disabled")
                : false;
              updateRejectBtn
                ? updateRejectBtn.removeAttribute("disabled", "disabled")
                : false;
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = TextTrackCueList;
                updateBtn
                  ? updateBtn.removeAttribute("disabled", "disabled")
                  : false;
                approveBtn
                  ? approveBtn.removeAttribute("disabled", "disabled")
                  : false;
                rejectBtn
                  ? rejectBtn.removeAttribute("disabled", "disabled")
                  : false;
                updateRejectBtn
                  ? updateRejectBtn.removeAttribute("disabled", "disabled")
                  : false;
                bodyWrapper.classList.remove("non-clickable");
              });

              this.formData = new FormData();
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords(
              "Please fill up the required fields."
            ),
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });

          btnLoader.classList.add("hidden");
          updateBtn ? updateBtn.removeAttribute("disabled", "disabled") : false;
          approveBtn
            ? approveBtn.removeAttribute("disabled", "disabled")
            : false;
          rejectBtn ? rejectBtn.removeAttribute("disabled", "disabled") : false;
          updateRejectBtn
            ? updateRejectBtn.removeAttribute("disabled", "disabled")
            : false;
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    async updateAdvertisement() {
      const bodyWrapper = document.getElementById("processAdvertisementForm");
      const updateBtn = document.getElementById("processAdvertisementUpdates");
      const approveBtn = document.getElementById("approveBtn");
      const rejectBtn = document.getElementById("rejectBtn");
      const updateRejectBtn = document.getElementById("updateRejectBtn");
      const btnLoader = updateBtn.querySelector(".btn-loader");

      btnLoader.classList.remove("hidden");
      bodyWrapper.classList.add("non-clickable");
      updateBtn.setAttribute("disabled", "disabled");
      approveBtn ? approveBtn.setAttribute("disabled", "disabled") : false;
      rejectBtn ? rejectBtn.setAttribute("disabled", "disabled") : false;
      updateRejectBtn
        ? updateRejectBtn.setAttribute("disabled", "disabled")
        : false;

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let advertisementData = this.advertisement;
          advertisementData.newImagesCoordinates = [];
          advertisementData.newCoverCoordinates = [];
          // Prevent overwriting adminNote
          delete advertisementData.adminNote;

          const formData = new FormData()
          console.log(this.business.images.otherImage)
          if (this.business.images.otherImage.length == 0) {
            this.hasImageError = true;
            this.imageError = "Please upload images";
          }

          let user = {
            id: this.advertisementID,
            business: {
              name: this.advertisement.buisnessName,
              location: {
                address: {
                  line1: this.advertisement.businessAddress,
                  line2: this.advertisement.businessAddressLine2
                }
              },
              website: this.advertisement.website,
              email: this.advertisement.businessEmail,
              phone: this.advertisement.businessPhoneNumber,
              images: this.business.images,

            },
            advertisement: {
              city: this.advertisement.city,
              page: this.page,
              category: this.category
            },
            billing: {
              address: {
                address: [1, 2],
                city: '11',
                state: '11',
                zipCode: 'required',
                paymentMethod: {},
                card: {},
              },
            },
            selectedDate: this.selectedDate,
            adDuration: this.advertisement.adDuration,
            preview_coordinates: {}
          };

          // cropper stores 3 other fields
          const { canvas, coordinates } = this.$refs.cropper[0].getResult();
          const cover = this.dataURLtoFile(
            canvas.toDataURL(),
            this.cover.url
          );
          user.preview_coordinates = coordinates;
          formData.append("images_preview", cover);


          formData.set("ad", JSON.stringify(user));
          this.$store
            .dispatch("advertisement/UPDATE_ADVERTISEMENT", { advertisementData: formData, id: this.advertisementID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Advertisement successfully updated"
                  ),
                  timeout: 4000,
                  icon: "fas fa-check",
                  type: "success",
                });
                this.$router.push("/advertisement/list");
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                (this.role = null),
                  (this.advertisement = {
                    name: "",
                    address: {
                      line1: "",
                      line2: "",
                    },
                    email: "",
                    contactNumber: "",
                  });
              } else {
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Admin already exists"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error updating admin user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }

                this.formData = new FormData();
              }

              btnLoader.classList.add("hidden");
              updateBtn
                ? updateBtn.removeAttribute("disabled", "disabled")
                : false;
              approveBtn
                ? approveBtn.removeAttribute("disabled", "disabled")
                : false;
              rejectBtn
                ? rejectBtn.removeAttribute("disabled", "disabled")
                : false;
              updateRejectBtn
                ? updateRejectBtn.removeAttribute("disabled", "disabled")
                : false;
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = TextTrackCueList;
                updateBtn
                  ? updateBtn.removeAttribute("disabled", "disabled")
                  : false;
                approveBtn
                  ? approveBtn.removeAttribute("disabled", "disabled")
                  : false;
                rejectBtn
                  ? rejectBtn.removeAttribute("disabled", "disabled")
                  : false;
                updateRejectBtn
                  ? updateRejectBtn.removeAttribute("disabled", "disabled")
                  : false;
                bodyWrapper.classList.remove("non-clickable");
              });

              this.formData = new FormData();
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords(
              "Please fill up the required fields."
            ),
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "warning",
          });

          btnLoader.classList.add("hidden");
          updateBtn ? updateBtn.removeAttribute("disabled", "disabled") : false;
          approveBtn
            ? approveBtn.removeAttribute("disabled", "disabled")
            : false;
          rejectBtn ? rejectBtn.removeAttribute("disabled", "disabled") : false;
          updateRejectBtn
            ? updateRejectBtn.removeAttribute("disabled", "disabled")
            : false;
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    approveAdvertisement(advertisementID) {
      this.$store
        .dispatch("advertisement/APPROVE_ADVERTISEMENT", advertisementID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Approve Advertisement Successfully"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.$router.push("/advertisement/list");
          } else {
            if (res.data.error === "error_user_already_exist") {
              this.$notify({
                message: this.$options.filters.capitalizeWords(
                  "Admin already exists"
                ),
                timeout: 4000,
                icon: "fas fa-exclamation-triangle",
                type: "danger",
              });
            } else {
              this.$notify({
                message: this.$options.filters.capitalizeWords(
                  "Error updating admin user"
                ),
                timeout: 4000,
                icon: "fas fa-exclamation-triangle",
                type: "danger",
              });
            }

            this.formData = new FormData();
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
    rejectAdvertisement() {
      if (this.rejectClicked) {
        return this.$validator
          .validate("adminNote", this.adminNote)
          .then((valid) => {
            if (valid) {
              const advertisementData = {
                showAd: false,
                adminNote: this.adminNote,
                status: "rejected",
                featured: null,
              };
              this.$store
                .dispatch("advertisement/REJECT_ADVERTISEMENT", {
                  advertisementID: this.advertisementID, advertisementData: advertisementData,
                })
                .then((res) => {
                  if (res.data.success) {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        "Reject Advertisement Successfully"
                      ),
                      timeout: 4000,
                      icon: "fas fa-check",
                      type: "success",
                    });
                    this.$router.push("/advertisement/list");
                  } else {
                    if (res.data.error === "error_user_already_exist") {
                      this.$notify({
                        message: this.$options.filters.capitalizeWords(
                          "Admin already exists"
                        ),
                        timeout: 4000,
                        icon: "fas fa-exclamation-triangle",
                        type: "danger",
                      });
                    } else {
                      this.$notify({
                        message: this.$options.filters.capitalizeWords(
                          "Error updating admin user"
                        ),
                        timeout: 4000,
                        icon: "fas fa-exclamation-triangle",
                        type: "danger",
                      });
                    }

                    this.formData = new FormData();
                  }

                  promiseBtnInnerApprove.classList.add("hidden");
                  promiseBtnInnerReject.classList.add("hidden");
                  promiseBtnInner.classList.add("hidden");
                  promiseBtnApprove.removeAttribute("disabled", "disabled");
                  promiseBtnReject.removeAttribute("disabled", "disabled");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                })
                .catch((err) => {
                  console.error(err);
                  err.then((error) => {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        error.errMessage
                      ),
                      timeout: 4000,
                      icon: "fas fa-exclamation-triangle",
                      type: "danger",
                    });
                    this.hasError = true;
                    promiseBtnInnerApprove.classList.add("hidden");
                    promiseBtnInnerReject.classList.add("hidden");
                    promiseBtnApprove.removeAttribute("disabled", "disabled");
                    promiseBtnReject.removeAttribute("disabled", "disabled");
                    bodyWrapper.classList.remove("non-clickable");
                    promiseBtnInner.classList.add("hidden");
                    promiseBtn.removeAttribute("disabled", "disabled");
                  });
                });
            } else {
              this.$notify({
                message: this.$options.filters.capitalizeWords(
                  "Please enter a Reason"
                ),
                timeout: 4000,
                icon: "fas fa-check",
                type: "warning",
              });
            }
          });
      } else {
        this.showReasonField = true;
        this.showApproveButton = false;
        this.rejectClicked = true;
        this.showReasonField = true;
        this.rejectStatus = "";
        this.adminNote = "";
      }
    },
    updateRejectReason(adminNote) {
      return this.$validator
        .validate("reason", this.advertisement.adminNote)
        .then((valid) => {
          if (valid) {
            const advertisementData = {
              showAd: false,
              adminNote: adminNote,
              status: "rejected",
            };
            this.$store
              .dispatch("advertisement/REJECT_ADVERTISEMENT", {
                advertisementID: this.advertisementID,
                advertisementData,
              })
              .then((res) => {
                if (res.data.success) {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Reject Advertisement Successfully"
                    ),
                    timeout: 4000,
                    icon: "fas fa-check",
                    type: "success",
                  });
                  this.$router.push("/advertisement/list");
                } else {
                  if (res.data.error === "error_user_already_exist") {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        "Admin already exists"
                      ),
                      timeout: 4000,
                      icon: "fas fa-exclamation-triangle",
                      type: "danger",
                    });
                  } else {
                    this.$notify({
                      message: this.$options.filters.capitalizeWords(
                        "Error updating admin user"
                      ),
                      timeout: 4000,
                      icon: "fas fa-exclamation-triangle",
                      type: "danger",
                    });
                  }

                  this.formData = new FormData();
                }

                promiseBtnInnerApprove.classList.add("hidden");
                promiseBtnInnerReject.classList.add("hidden");
                promiseBtnInner.classList.add("hidden");
                promiseBtnApprove.removeAttribute("disabled", "disabled");
                promiseBtnReject.removeAttribute("disabled", "disabled");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              })
              .catch((err) => {
                console.error(err);
                err.then((error) => {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      error.errMessage
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  promiseBtnInnerApprove.classList.add("hidden");
                  promiseBtnInnerReject.classList.add("hidden");
                  promiseBtnApprove.removeAttribute("disabled", "disabled");
                  promiseBtnReject.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                });
              });
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Please enter a Reason"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "warning",
            });
          }
        });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
        "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
        false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    onFileChange(e) {
      const { files } = e.target;
      const self = this;

      this.hasImageError = false;

      if (files.length > this.allowedImages) {
        self.business.images.otherImage = [];
        self.urlImage = [];

        return self.$notify({
          message: `You can only upload upto ${this.allowedImages} image(s).`,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }

      self.business.images.otherImage = [];
      self.business.images.mainImage = undefined;

      for (var i = 0; i < files.length; i++) {
        self.business.images.otherImage.push({
          index: i,
          fileName: files[i].name,
          image: URL.createObjectURL(files[i]),
        });
        self.formData.append("images", files[i]);
      }
      self.business.images.mainImage = 0;
    },

    getCategories() {
      if (this.page !== null && this.page === '602cc007e5ba430015dbc5f6') {
        this.$store.dispatch("advertisement/GET_ADVERTISEMENT_CATEGORY_LIST", {
          query: {
            page: this.page,
            show: true
          },
        });
      }
    },
    getCities() {
      this.$store.dispatch("cities/GET_CITIES", { city: "All" });
    },
  },
  beforeMount() {
    this.getCities();
    this.getCategories();
    this.action = this.$route.params.id ? "update" : "create";
    this.advertisementID = this.$route.params.id;
    if (this.$route.params.id) {
      this.getAdvertisementInformation(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss" scoped>
.row {
  justify-content: space-around;
}

.createAdminContainer select {
  color: #525f7f !important;
}

.progress {
  height: 14px !important;
}

.form-title {
  text-align: center;
  text-transform: uppercase;
}

.preview-image {
  h3 {
    display: block;
    margin: auto;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .cover {
    width: 300px;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .cover-two {
    background: #db9a26;
    width: 100%;
    padding: 10px;

    img {
      width: 100%;
    }

    h3 {
      color: white;
      margin-bottom: 0;
    }
  }

  .images {
    margin: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.form-control {
  color: #525f7f !important;
}

.img-container {
  height: auto;
  margin-bottom: 12px;

  .action {
    display: flex;
    justify-content: center;

    .action-icon {
      margin: 0 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .select-icon {
      color: #0cb799;
    }

    .selected-icon {
      color: var(--primary);
    }

    .delete-icon {
      color: rgba(255, 0, 0, 0.733);
    }

    .update-icon {
      color: royalblue;
    }

    .add-icon {
      color: var(--primary);
      text-align: center;
      border: 1px solid var(--primary);
      padding: 5px 10px;
      margin-top: 25px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: var(--primary);
        color: white;
      }

      p {
        font-size: 15px;
        font-weight: 900;
      }
    }
  }

  .current-img {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    border: 3px solid var(--primary);

    img {
      opacity: 1;
      display: block;
      margin: auto;
    }
  }
}

.new-image-container {
  h3 {
    text-align: center;
  }
}

.img-title {
  display: block;
  margin: auto;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}

.reason-field {
  border: 2px solid #f4516c;
}

.reason-label {
  text-transform: uppercase;
  font-weight: 900;
  color: #f4516c;
}

.note-important {
  color: red;
}

li {
  list-style: none;
}

.small-note {
  color: black;
}

.btn-loader {
  font-size: 20px;

  &.hidden {
    display: none;
  }
}

.selected {
  background-color: var(--primary) !important;
}

.subscription-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subscription-card {
  padding: 30px 30px 10px;
  height: auto;
  width: 400px;
  border: 1px solid white;
  background: linear-gradient(220deg,
      rgba(58, 55, 55, 1) 0%,
      rgba(27, 27, 27, 1) 100%) !important;
  color: white;

  h3 {
    width: 85%;
    min-height: 75px;
    color: white;
    font-size: 25px;
    font-weight: 900;
  }

  p {
    margin-top: 20px;
    margin-bottom: -10px;
  }

  .as-low {
    margin-top: 10px;
  }

  .price {
    font-size: 40px;
    font-weight: 900;
    color: var(--primary);

    span {
      font-size: 20px;
      font-weight: 200;
    }
  }

  .trial {
    width: 100%;
    font-weight: 600;
    margin-top: 10px;
  }

  .select-btn {
    display: block;
    margin: 20px auto;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background: black;
    color: white;

    font-weight: 900;
  }

  .description {
    list-style: armenian !important;
  }

  &.selectedSubscription {
    position: relative;

    h3 {
      color: var(--primary);
    }

    .select-btn {
      background: var(--primary);

      &:focus {
        outline-color: black;
        border: none;
      }
    }

    // border: 5px solid var(--primary);
  }

  .note-selected {
    img {
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      width: 40px;
    }
  }
}
</style>
